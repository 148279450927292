const HomeRoutes = {
  createHomeBanner: "/createHomeBanner",
  getHomeBanner: "/getHomeBanner",
  updateHomeBanner: "/updateHomeBanner",
  createWhoWeAre: "/createWhoWeAre",
  getWhoWeAre: "/getWhoWeAre",
  updateWhoWeAre: "/updateWhoWeAre",
  createIndustryWeMove: "/createIndustryWeMove",
  getIndustryWeMove: "/getIndustryWeMove",
  updateIndustryWeMove: "/updateIndustryWeMove",
};

export default HomeRoutes;
