import { ExpandMore } from "@mui/icons-material";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Box,
  Typography,
} from "@mui/material";
import AboutServices from "app/apis/About/AboutServices";
import { PrimaryButton } from "app/components/Buttons/Button";
import { SuccessToaster } from "app/components/Toaster/Toaster";
import { InputField } from "app/components/UI/TextField";
import colors from "app/styles/colors";
import React, { useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";

function GeneralContent({ updateMainLoader }) {
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    getValues,
  } = useForm();

  const [itemId, setItemId] = useState(null);
  const [loader, setLoader] = useState(true);

  const handleEditorData = async (formData) => {
    try {
      let result = "";
      if (itemId) {
        result = await AboutServices.updateGeneralContent({
          ...formData,
          id: itemId,
        });
      } else {
        result = await AboutServices.createGeneralContent(formData);
      }
      SuccessToaster(result?.message);
      updateMainLoader();
    } catch (error) {
      console.log("🚀 ~ handleEditorData ~ error:", error);
    }
  };

  const getGeneralContent = async () => {
    try {
      let { data } = await AboutServices.getGeneralContent();
      setValue("ourmission", data[0].ourmission);
      setValue("ourvision", data[0].ourvision);
      setValue("sustainability", data[0].sustainability);
      setValue("technology", data[0].technology);
      setValue("customersupport", data[0].customersupport);
      setItemId(data[0]?.id);
    } catch (error) {
      console.log("🚀 ~ file: Home.js:70 ~ getAboutPTN ~ error:", error);
    } finally {
      setLoader(false);
    }
  };

  useEffect(() => {
    getGeneralContent();
  }, []);

  return (
    <Accordion
      sx={{
        mb: 1,
        boxShadow: "none",
        "&::before": {
          display: "none",
        },
      }}
    >
      <AccordionSummary expandIcon={<ExpandMore />}>
        <Typography variant="body1" fontWeight="bold" color="initial">
          General Content
        </Typography>
      </AccordionSummary>
      <AccordionDetails>
        {!loader && (
          <Box sx={{ bgcolor: colors.white, borderRadius: "12px" }}>
            <Typography
              component={"form"}
              onSubmit={handleSubmit(handleEditorData)}
            >
              <Box sx={{ mt: 2, display: "flex", flexDirection: "column" }}>
                <InputField
                  label="Our Mission"
                  error={errors?.ourmission?.message}
                  register={register("ourmission", {
                    required: "It's required.",
                    maxLength: {
                      value: 50,
                      message: "Max character limit 50.",
                    },
                  })}
                />
                <InputField
                  label="Our Vision"
                  error={errors?.ourvision?.message}
                  register={register("ourvision", {
                    required: "It's required.",
                    maxLength: {
                      value: 50,
                      message: "Max character limit 50.",
                    },
                  })}
                />
                <InputField
                  label="Sustainability"
                  error={errors?.sustainability?.message}
                  register={register("sustainability", {
                    required: "It's required.",
                    maxLength: {
                      value: 50,
                      message: "Max character limit 50.",
                    },
                  })}
                />
                <InputField
                  label="Customer Support"
                  error={errors?.customersupport?.message}
                  register={register("customersupport", {
                    required: "It's required.",
                    maxLength: {
                      value: 50,
                      message: "Max character limit 50.",
                    },
                  })}
                />
                <InputField
                  label="Technology"
                  error={errors?.technology?.message}
                  register={register("technology", {
                    required: "It's required.",
                    maxLength: {
                      value: 50,
                      message: "Max character limit 50.",
                    },
                  })}
                />
              </Box>

              <PrimaryButton
                title="Save"
                type="submit"
                //   loading={loading}
                fullWidth={true}
              />
            </Typography>
          </Box>
        )}
      </AccordionDetails>
    </Accordion>
  );
}

export default GeneralContent;
