import { get, post, patch, deleted } from "app/apis";
import HomeRoutes from "./HomeRoutes";

const HomeService = {
  getHomeBanner: async () => {
    const data = await get(HomeRoutes.getHomeBanner);
    return data;
  },
  createHomeBanner: async (obj) => {
    const data = await post(HomeRoutes.createHomeBanner, obj);
    return data;
  },
  updateHomeBanner: async (obj) => {
    const data = await post(HomeRoutes.updateHomeBanner, obj);
    return data;
  },

  getWhoWeAre: async () => {
    const data = await get(HomeRoutes.getWhoWeAre);
    return data;
  },
  createWhoWeAre: async (obj) => {
    const data = await post(HomeRoutes.createWhoWeAre, obj);
    return data;
  },
  updateWhoWeAre: async (obj) => {
    const data = await post(HomeRoutes.updateWhoWeAre, obj);
    return data;
  },

  getIndustryWeMove: async () => {
    const data = await get(HomeRoutes.getIndustryWeMove);
    return data;
  },
  createIndustryWeMove: async (obj) => {
    const data = await post(HomeRoutes.createIndustryWeMove, obj);
    return data;
  },
  updateIndustryWeMove: async (obj) => {
    const data = await post(HomeRoutes.updateIndustryWeMove, obj);
    return data;
  },
};

export default HomeService;
