const OurLogisticsRoutes = {
  createMainText: "/createMainText",
  getMainText: "/getMainText",
  updateMainText: "/updateMainText",

  createSubText: "/createSubText",
  getSubText: "/getSubText",
  updateSubText: "/updateSubText",

  getLogCard: "/getLogCard",
  createLogCard: "/createLogCard",
  updateLogCard: "/updateLogCard",
};

export default OurLogisticsRoutes;
