import React, { useEffect, useRef, useState } from "react";
import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  Typography,
} from "@mui/material";
import { CloseRounded } from "@mui/icons-material";
import { PrimaryButton } from "../Buttons/Button";
import BookingsServices from "app/apis/Bookings/BookingsServices";
import { ErrorToaster, SuccessToaster } from "../Toaster/Toaster";

function UploadInvoicePOD({ handleClose, open, data, moduleName }) {
  const [file, setFile] = useState(null);

  const uploadData = async () => {
    if (file) {
      let formData = new FormData();
      formData.append("id", data?.id);
      if (typeof file == "object") {
        formData.append(data?.type, file, file?.name);
      }
      try {
        await BookingsServices.uploadInvPodFor(moduleName, formData);
        SuccessToaster("File uploaded.");
        handleClose();
      } catch (error) {
        ErrorToaster(error);
        console.log("🚀 ~ handleDataSubmission ~ error:", error);
      }
    } else {
      ErrorToaster("File is required. ");
    }
  };

  useEffect(() => {
    if (!open) {
      setFile(null);
    }
  }, [open]);

  return (
    <Dialog
      open={open}
      fullWidth
      onClose={() => {
        handleClose();
      }}
      sx={{ "& .MuiDialog-paper": { borderRadius: "12px" } }}
    >
      <DialogTitle
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          textTransform: "uppercase",
        }}
      >
        Upload {data?.type}
        <IconButton
          onClick={() => {
            handleClose();
          }}
        >
          <CloseRounded />
        </IconButton>
      </DialogTitle>
      <Divider />
      <DialogContent>
        <Box mb={2}>
          <input
            type="file"
            onChange={(e) => setFile(e.target.files[0])}
            name="upload"
            accept="application/pdf"
          />
        </Box>
        <PrimaryButton
          onClick={uploadData}
          myStyle={{ mt: 1, borderRadius: "12px" }}
          fullWidth={true}
          title="Save"
        />
      </DialogContent>
    </Dialog>
  );
}

export default UploadInvoicePOD;
