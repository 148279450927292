import React, { useEffect, useState } from "react";
import { Box, Grid, Typography } from "@mui/material";
import Layout from "app/layout/Layout";
import colors from "app/styles/colors";
import BreadCrumbs from "app/components/Breadcrumbs/Breadcrumbs";
import TextEditor from "../../components/Editors/TextEditor";
import ImageEditor from "../../components/Editors/ImageEditor";
import GeneralServices from "app/apis/General/GeneralServices";
import { ErrorToaster, SuccessToaster } from "app/components/Toaster/Toaster";
import { LoadingCircle } from "app/components/UI/Loader";
import ContactUsServices from "app/apis/ContactUs/ContactUsServices";

function ContactUs() {
  const [loader, setLoader] = useState(true);
  const [mainBanner, setMainBanner] = useState("");
  const [sectionImage, setSectionImage] = useState("");
  const [contactUsContent, setContactUsContent] = useState({});

  const submitBanner = async (route, data, oldData) => {
    try {
      let formData = new FormData();
      {
        oldData?.id && formData.append("id", oldData?.id);
      }
      formData.append("image", data, data?.name);
      let result = "";
      if (oldData) {
        result = await GeneralServices.updateBanner(route, formData);
      } else {
        result = await GeneralServices.createBanner(route, formData);
      }
      SuccessToaster(result?.message);
      setLoader(true);
    } catch (error) {
      ErrorToaster(error);
      console.log("🚀 ~ submitBanner ~ error:", error);
    }
  };

  const handleContactMainText = async (data) => {
    try {
      let formData = new FormData();
      {
        contactUsContent?.id && formData.append("id", contactUsContent?.id);
      }
      formData.append("description", data.description);
      let result = "";
      if (contactUsContent?.id) {
        result = await ContactUsServices.updateContactUsMainText(formData);
      } else {
        result = await ContactUsServices.createContactUsMainText(formData);
      }
      SuccessToaster(result?.message);
      setLoader(true);
    } catch (error) {
      console.log("🚀 ~ handleContactMainText ~ error:", error);
    }
  };

  // get apis ===========
  const getAllImages = async () => {
    try {
      let result = await GeneralServices.getBanner("ContactUsBanner");
      let result1 = await GeneralServices.getBanner("SectionImage");
      setMainBanner(result?.data[0]);
      setSectionImage(result1?.data[0]);
    } catch (error) {
      console.log("🚀 ~ getAllImages ~ error:", error);
    }
  };

  const getTextContent = async () => {
    try {
      let result = await ContactUsServices.getContactUsMainText();
      setContactUsContent(result?.data[0]);
    } catch (error) {
      console.log("🚀 ~ getOurVision ~ error:", error);
    }
  };
  const getAllData = async () => {
    try {
      await getAllImages();
      await getTextContent();
    } catch (error) {
      console.log("🚀 ~ getAllData ~ error:", error);
    } finally {
      setLoader(false);
    }
  };

  useEffect(() => {
    if (loader) {
      getAllData();
    }
  }, [loader]);
  return (
    <Layout>
      <Box
        sx={{ backgroundColor: colors.lightGray, p: 3, borderRadius: "12px" }}
      >
        <Grid
          container
          alignItems="center"
          justifyContent="space-between"
          sx={{ bgcolor: colors.white, p: 2, borderRadius: "12px" }}
        >
          <Grid item xs={6}>
            <Typography variant="body1" fontWeight="bold" color="initial">
              Contact Us Management
            </Typography>
          </Grid>
          <Grid
            item
            xs={6}
            sx={{ display: "flex", justifyContent: "flex-end" }}
          >
            <BreadCrumbs currentPage="Contact Us Management" />
          </Grid>
        </Grid>
        {loader ? (
          <LoadingCircle />
        ) : (
          <Box sx={{ mt: 2 }}>
            <ImageEditor
              title={"Banner Image"}
              handleData={(e) => submitBanner("ContactUsBanner", e, mainBanner)}
              uploadedImage={mainBanner}
            />
            <TextEditor
              title="Main Text"
              characterLimit={250}
              data={contactUsContent}
              handleData={(e) => handleContactMainText(e)}
            />
            <ImageEditor
              title={"Section Image"}
              handleData={(e) => submitBanner("SectionImage", e, sectionImage)}
              uploadedImage={sectionImage}
            />
          </Box>
        )}
      </Box>
    </Layout>
  );
}

export default ContactUs;
