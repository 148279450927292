import { get, post, patch, deleted } from "app/apis";
import FreightsRoutes from "./FreightsRoutes";

const FreightsServices = {
  create: async (routeName, obj) => {
    const data = await post(FreightsRoutes.create + `${routeName}`, obj);
    return data;
  },
  get: async (routeName) => {
    const data = await get(FreightsRoutes.get + `${routeName}`);
    return data;
  },
  update: async (routeName, obj) => {
    const data = await post(FreightsRoutes.update + `${routeName}`, obj);
    return data;
  },
};

export default FreightsServices;
