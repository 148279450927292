import { get, post, patch, deleted } from "app/apis";
import CareerRoutes from "./CareerRoutes";

const CareerServices = {
  createCareerJob: async (obj) => {
    const data = await post(CareerRoutes.createCareerJob, obj);
    return data;
  },
  getCareerJob: async () => {
    const data = await get(CareerRoutes.getCareerJob);
    return data;
  },
  getCareerJobProposal: async (params) => {
    const data = await get(CareerRoutes.getCareerJobProposal, params);
    return data;
  },
  updateCareerJob: async (obj) => {
    const data = await post(CareerRoutes.updateCareerJob, obj);
    return data;
  },
  deleteCareerJob: async (obj) => {
    const data = await post(CareerRoutes.deleteCareerJob, obj);
    return data;
  },
};

export default CareerServices;
