import React, { useEffect, useState } from "react";
import { Box, Grid, Typography } from "@mui/material";
import Layout from "app/layout/Layout";
import colors from "app/styles/colors";
import ImageEditorCombine from "app/components/Editors/ImageEditorCombine";
import TextEditorCombine from "app/components/Editors/TextEditorCombine";
import { PrimaryButton } from "app/components/Buttons/Button";
import { checkCharacterValidation, checkImageValidation } from "app/utils";
import { ErrorToaster } from "app/components/Toaster/Toaster";
import FreightsServices from "app/apis/Freights/FreightsServices";
import { LoadingCircle } from "app/components/UI/Loader";

let characterLength = {
  mainContent: 870,
  subContent: 330,
  topContent: 200,
};

function AirFreight() {
  const [allImages, setAllImages] = useState({});
  const [allContents, setAllContents] = useState({});
  const [itemData, setItemData] = useState({});
  const [loader, setLoader] = useState(true);

  const handleDataSubmission = async () => {
    try {
      if (
        checkCharacterValidation(
          characterLength.mainContent,
          allContents?.mainContent?.length,
          "Main Content"
        ) &&
        checkCharacterValidation(
          characterLength.topContent,
          allContents?.airContent?.length,
          "About Air Content"
        ) &&
        checkCharacterValidation(
          characterLength.subContent,
          allContents?.firstSection?.length,
          "First Seciton Content"
        ) &&
        checkCharacterValidation(
          characterLength.subContent,
          allContents?.secondSection?.length,
          "Second Section Content"
        ) &&
        checkImageValidation(allImages?.bannerImage, "Main Banner") &&
        checkImageValidation(allImages?.airImage, "About Air") &&
        checkImageValidation(allImages?.firstImage, "First") &&
        checkImageValidation(allImages?.secondImage, "Second") &&
        checkImageValidation(allImages?.firstSectionImage, "First Section") &&
        checkImageValidation(allImages?.secondSectionImage, "Second Section")
      ) {
        let formData = new FormData();
        {
          itemData?.id && formData.append("id", itemData?.id);
        }

        formData.append("description", allContents?.mainContent?.text);
        formData.append("about_description", allContents?.airContent?.text);
        formData.append(
          "section_first_description",
          allContents?.firstSection?.text
        );
        formData.append(
          "section_second_description",
          allContents?.secondSection?.text
        );

        if (typeof allImages?.bannerImage == "object") {
          formData.append(
            "banner_image",
            allImages?.bannerImage,
            allImages?.bannerImage?.name
          );
        }
        if (typeof allImages?.airImage == "object") {
          formData.append(
            "about_image",
            allImages?.airImage,
            allImages?.airImage?.name
          );
        }
        if (typeof allImages?.firstImage == "object") {
          formData.append(
            "first_image",
            allImages?.firstImage,
            allImages?.firstImage?.name
          );
        }
        if (typeof allImages?.secondImage == "object") {
          formData.append(
            "second_image",
            allImages?.secondImage,
            allImages?.secondImage?.name
          );
        }
        if (typeof allImages?.firstSectionImage == "object") {
          formData.append(
            "section_first_image",
            allImages?.firstSectionImage,
            allImages?.firstSectionImage?.name
          );
        }
        if (typeof allImages?.secondSectionImage == "object") {
          formData.append(
            "section_second_image",
            allImages?.secondSectionImage,
            allImages?.secondSectionImage?.name
          );
        }

        if (itemData?.id) {
          await FreightsServices.update("AirFreight", formData);
        } else {
          await FreightsServices.create("AirFreight", formData);
        }
        setLoader(true);
      }
    } catch (error) {
      ErrorToaster(error);
      console.log("🚀 ~ handleDataSubmission ~ error:", error);
    }
  };

  const getItemData = async () => {
    let res = await FreightsServices.get("AirFreight");
    let data = res?.data[0];
    setItemData(data);
    setAllContents({
      mainContent: {
        text: data?.description,
        length: data?.description?.length,
      },
      airContent: {
        text: data?.about_description,
        length: data?.about_description?.length,
      },
      firstSection: {
        text: data?.section_first_description,
        length: data?.section_first_description?.length,
      },
      secondSection: {
        text: data?.section_second_description,
        length: data?.section_second_description?.length,
      },
    });
    setAllImages({
      bannerImage: data?.banner_image,
      airImage: data?.about_image,
      firstImage: data?.first_image,
      secondImage: data?.second_image,
      firstSectionImage: data?.section_first_image,
      secondSectionImage: data?.section_second_image,
    });
    setLoader(false);
    console.log("🚀 ~ getItemData ~ data:", data);
  };

  useEffect(() => {
    if (loader) {
      getItemData();
    }
  }, [loader]);
  return (
    <Layout>
      <Box
        sx={{ backgroundColor: colors.lightGray, p: 3, borderRadius: "12px" }}
      >
        <Grid
          container
          alignItems="center"
          justifyContent="space-between"
          sx={{ bgcolor: colors.white, p: 2, borderRadius: "12px" }}
        >
          <Grid item xs={6}>
            <Typography variant="body1" fontWeight="bold" color="initial">
              Air Freight Management
            </Typography>
          </Grid>
        </Grid>

        {loader ? (
          <LoadingCircle />
        ) : (
          <Box sx={{ mt: 2 }}>
            <ImageEditorCombine
              title={"Banner Image"}
              handleData={(e) => setAllImages({ ...allImages, bannerImage: e })}
              uploadedImage={allImages?.bannerImage}
            />
            <TextEditorCombine
              title="About Air Freight"
              characterLimit={characterLength.topContent}
              data={itemData?.about_description}
              handleData={(e, contentLength) =>
                setAllContents({
                  ...allContents,
                  airContent: {
                    text: e,
                    length: contentLength,
                  },
                })
              }
            />
            <ImageEditorCombine
              title={"About Air Freight Image"}
              handleData={(e) => setAllImages({ ...allImages, airImage: e })}
              uploadedImage={allImages?.airImage}
            />
            <ImageEditorCombine
              title={"First Image"}
              handleData={(e) => setAllImages({ ...allImages, firstImage: e })}
              uploadedImage={allImages?.firstImage}
            />
            <ImageEditorCombine
              title={"Second Image"}
              handleData={(e) => setAllImages({ ...allImages, secondImage: e })}
              uploadedImage={allImages?.secondImage}
            />
            <TextEditorCombine
              title="Main Content"
              characterLimit={characterLength.mainContent}
              data={itemData?.description}
              handleData={(e, contentLength) =>
                setAllContents({
                  ...allContents,
                  mainContent: {
                    text: e,
                    length: contentLength,
                  },
                })
              }
            />

            <TextEditorCombine
              title="First Section Content"
              characterLimit={characterLength.subContent}
              data={itemData?.section_first_description}
              handleData={(e, contentLength) =>
                setAllContents({
                  ...allContents,
                  firstSection: {
                    text: e,
                    length: contentLength,
                  },
                })
              }
            />
            <ImageEditorCombine
              title={"First Section Image"}
              handleData={(e) =>
                setAllImages({ ...allImages, firstSectionImage: e })
              }
              uploadedImage={allImages?.firstSectionImage}
            />
            <TextEditorCombine
              title="Second Section Content"
              characterLimit={characterLength.subContent}
              data={itemData?.section_second_description}
              handleData={(e, contentLength) =>
                setAllContents({
                  ...allContents,
                  secondSection: {
                    text: e,
                    length: contentLength,
                  },
                })
              }
            />
            <ImageEditorCombine
              title={"Second Section Image"}
              handleData={(e) =>
                setAllImages({ ...allImages, secondSectionImage: e })
              }
              uploadedImage={allImages?.secondSectionImage}
            />
            <PrimaryButton
              title="Save"
              onClick={() => handleDataSubmission()}
              style={{
                margin: "10px 0px",
              }}
            />
          </Box>
        )}
      </Box>
    </Layout>
  );
}

export default AirFreight;
