import React, { Fragment, useEffect, useState } from "react";
import { Box, Divider, Grid, Typography } from "@mui/material";
import { BsBookmarksFill } from "react-icons/bs";

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { makeStyles } from "@mui/styles";
import { Bar } from "react-chartjs-2";
import { faker } from "@faker-js/faker";
import { ArrowForwardIos, ExpandLess, ExpandMore } from "@mui/icons-material";
import clsx from "clsx";
import colors from "app/styles/colors";
import Layout from "app/layout/Layout";
import SecondaryCard from "app/components/Cards/SecondaryCard";
import PrimaryCard from "app/components/Cards/PrimaryCard";
import DashboardServices from "app/apis/Dashboard/DashboardServices";

// *import components

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const useStyles = makeStyles({
  iconStyle: {
    fontSize: "30px",
    borderRadius: "8px",
    zIndex: 99,
    padding: "10px",
    position: "relative",
    top: -15,
  },
  smallIconStyle: {
    borderRadius: "8px",
    padding: 8,
    fontSize: "24px",
    marginRight: 12,
  },
  typeIconBgColor: {
    backgroundColor: "#363644",
  },
  makeIconBgColor: {
    backgroundColor: "#eddaa98f",
  },
});

function Dashboard() {
  const classes = useStyles();

  const [countData, setCountData] = useState({});
  let getCountData = async () => {
    try {
      let res = await DashboardServices.getCardsCount();
      setCountData(res?.data);
    } catch (error) {
      console.log("🚀 ~ getCountData ~ error:", error);
    }
  };

  useEffect(() => {
    getCountData();
  }, []);

  return (
    <Layout>
      <Fragment>
        <Grid
          container
          spacing={2}
          justifyContent={"space-between"}
          sx={{
            borderRadius: "12px",
            p: 3,
            width: "100%",
          }}
        >
          <Grid item xs={12} md={3}>
            <PrimaryCard
              icon={
                <BsBookmarksFill
                  className={classes.iconStyle}
                  color={colors.primaryLight}
                />
              }
              number={countData?.rent_warehouse_space}
              name={"Total Warehouse Bookings"}
              color={colors.primaryLight}
              bgColor={colors.primary}
              circle1={"#0c7548d4"}
              circle2={"#3f6a5838"}
            />
          </Grid>
          <Grid item xs={12} md={3}>
            <PrimaryCard
              icon={
                <BsBookmarksFill
                  className={classes.iconStyle}
                  color={colors.secondaryLight}
                />
              }
              number={countData?.move_your_car}
              name={"Total Car Bookings"}
              color={colors.secondaryLight}
              bgColor={colors.secondary}
              circle1={"#272636bf"}
              circle2={"#363644"}
            />
          </Grid>
          <Grid item xs={12} md={3}>
            <PrimaryCard
              icon={
                <BsBookmarksFill
                  className={classes.iconStyle}
                  color={colors.primaryLight}
                />
              }
              number={countData?.booking_sea_freight}
              name={"Total Sea Bookings"}
              color={colors.primaryLight}
              bgColor={colors.primary}
              circle1={"#0c7548d4"}
              circle2={"#3f6a5838"}
            />
          </Grid>
          <Grid item xs={12} md={3}>
            <PrimaryCard
              icon={
                <BsBookmarksFill
                  className={classes.iconStyle}
                  color={colors.primaryLight}
                />
              }
              number={countData?.booking_land_freight}
              name={"Total Land Bookings"}
              color={colors.secondaryLight}
              bgColor={colors.secondary}
              circle1={"#272636bf"}
              circle2={"#363644"}
            />
          </Grid>

          {/* <Grid item xs={12} md={3.9}>
            <SecondaryCard icon={<BsBookmarksFill
              className={clsx(classes.smallIconStyle, classes.typeIconBgColor)}
              color={colors.secondaryLight}
            />}
              number={"2"}
              name={"Vehicle Types"}
              nameColor={colors.secondaryLight}
              color={colors.whiteSmoke}
              bgColor={colors.secondary}
              circle1={"#272636bf"}
              circle2={"#363644"}
            />
            <SecondaryCard icon={<MdBuild
              className={clsx(classes.smallIconStyle, classes.makeIconBgColor)}
              color={colors.artyClickAmber}
            />}
              number={"24"}
              name={"Vehicle Make"}
              color={colors.gunMetal}
              nameColor={colors.artyClickAmber}
              bgColor={colors.eggSour}
              circle1={"#ffc1073d"}
              circle2={colors.eggSour}
            />
          </Grid> */}

          {/* <Grid item xs={12}>
            <Box
              sx={{
                backgroundColor: colors.white,
                my: 2,
                p: 2,
                borderRadius: "12px",
                height: 350,
              }}
            >
              <Bar options={options} data={data} />
            </Box>
          </Grid> */}
          {/* <Grid item xs={12} md={4}>
            <Box
              sx={{
                backgroundColor: colors.white,
                p: 2,
                my: 2,
                borderRadius: "12px",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Typography sx={{ fontWeight: "bold" }}>
                  Popular Drivers
                </Typography>
                <BiDotsVerticalRounded
                  style={{
                    transform: `rotate(90deg)`,
                    fontSize: "25px",
                    color: colors.textSecondary,
                    cursor: "pointer",
                  }}
                />
              </Box>
              {[...Array(Number(2))].map((item, index) => (
                <Fragment key={index}>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <Box>
                      <Typography
                        sx={{
                          fontSize: "16px",
                          fontWeight: "bold",
                          color: colors.textSecondary,
                        }}
                      >
                        Rushan
                      </Typography>
                      <Typography
                        sx={{ fontSize: "12px", color: colors.darkMintGreen }}
                      >
                        10% Profit
                      </Typography>
                    </Box>
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                      <Typography
                        sx={{
                          fontSize: "16px",
                          fontWeight: "bold",
                          color: colors.textSecondary,
                          mr: 3,
                        }}
                      >
                        $1839.00
                      </Typography>
                      <ExpandLess
                        sx={{
                          color: colors.primary,
                          backgroundColor: colors.primaryLight,
                          p: 0.5,
                          borderRadius: "6px",
                          fontSize: "18px",
                        }}
                      />
                    </Box>
                  </Box>
                  <Divider
                    sx={{
                      coolor: colors.textSecondary,
                      width: "100%",
                      my: 2,
                    }}
                  />
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <Box>
                      <Typography
                        sx={{
                          fontSize: "16px",
                          fontWeight: "bold",
                          color: colors.textSecondary,
                        }}
                      >
                        Shahriyar
                      </Typography>
                      <Typography
                        sx={{ fontSize: "12px", color: colors.grapeFruit }}
                      >
                        10% Loss
                      </Typography>
                    </Box>
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                      <Typography
                        sx={{
                          fontSize: "16px",
                          fontWeight: "bold",
                          color: colors.textSecondary,
                          mr: 3,
                        }}
                      >
                        $100.00
                      </Typography>
                      <ExpandMore
                        sx={{
                          color: colors.grapeFruit,
                          backgroundColor: colors.dawnPink,
                          p: 0.5,
                          borderRadius: "6px",
                          fontSize: "18px",
                        }}
                      />
                    </Box>
                  </Box>
                  <Divider
                    sx={{
                      coolor: colors.textSecondary,
                      width: "100%",
                      my: 2,
                    }}
                  />
                </Fragment>
              ))}
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  cursor: "pointer",
                  justifyContent: "center",
                  flexDirection: "row",
                }}
              >
                <Typography color={colors.textSecondary}>View All</Typography>
                <ArrowForwardIos sx={{ ml: 1, fontSize: "16px" }} />
              </Box>
            </Box>
          </Grid> */}
        </Grid>
      </Fragment>
    </Layout>
  );
}

export default Dashboard;
