import React, { useEffect, useRef, useState } from "react";
import { Box, Grid, Typography } from "@mui/material";
import Layout from "app/layout/Layout";
import colors from "app/styles/colors";
import BreadCrumbs from "app/components/Breadcrumbs/Breadcrumbs";
import ImageEditor from "app/components/Editors/ImageEditor";
import TextEditor from "app/components/Editors/TextEditor";
import GeneralContent from "./components/GeneralContent";
import { SuccessToaster } from "app/components/Toaster/Toaster";
import AboutServices from "app/apis/About/AboutServices";
import { LoadingCircle } from "app/components/UI/Loader";

function AboutPTN() {
  const [aboutBanner, setAboutBanner] = useState("");
  const [clippedImage, setClippedImage] = useState("");
  const [firstImage, setFirstImage] = useState("");
  const [secondImage, setSecondImage] = useState("");
  const [aboutPtn, setAboutPtn] = useState({});
  const [loader, setLoader] = useState(true);

  const submitAboutBanner = async (route, data, oldData) => {
    try {
      let formData = new FormData();
      {
        oldData?.id && formData.append("id", oldData?.id);
      }
      formData.append("image", data, data?.name);
      let result = "";
      if (oldData) {
        result = await AboutServices.updateAbouBanner(route, formData);
      } else {
        result = await AboutServices.createAboutBanner(route, formData);
      }
      SuccessToaster(result?.message);
      setLoader(true);
    } catch (error) {
      console.log("🚀 ~ submitAboutBanner ~ error:", error);
    }
  };

  const handleAboutPTN = async (data) => {
    try {
      let formData = new FormData();
      {
        aboutPtn?.id && formData.append("id", aboutPtn?.id);
      }
      formData.append("description", data.description);
      let result = "";
      if (aboutPtn?.id) {
        result = await AboutServices.updateAboutPtn(formData);
      } else {
        result = await AboutServices.createAboutPtn(formData);
      }
      SuccessToaster(result?.message);
      setLoader(true);
    } catch (error) {
      console.log("🚀 ~ handleAboutPTN ~ error:", error);
    }
  };

  // get apis ===========
  const getAboutBanner = async () => {
    try {
      let result = await AboutServices.getAboutBanner("AboutBanner");
      let result2 = await AboutServices.getAboutBanner("ClippedImg");
      let result3 = await AboutServices.getAboutBanner("FirstImg");
      let result4 = await AboutServices.getAboutBanner("SecondImg");
      setAboutBanner(result?.data[0]);
      setClippedImage(result2?.data[0]);
      setFirstImage(result3?.data[0]);
      setSecondImage(result4?.data[0]);
    } catch (error) {
      console.log("🚀 ~ file: Home.js:29 ~ getAboutBanner ~ error:", error);
    }
  };

  const getAboutPTN = async () => {
    try {
      let { data } = await AboutServices.getAboutPtn();
      setAboutPtn(data[0]);
    } catch (error) {
      console.log("🚀 ~ file: Home.js:70 ~ getAboutPTN ~ error:", error);
    }
  };

  const getAllData = async () => {
    try {
      await getAboutBanner();
      await getAboutPTN();
    } catch (error) {
      console.log("🚀 ~ getAllData ~ error:", error);
    } finally {
      setLoader(false);
    }
  };

  useEffect(() => {
    if (loader) {
      getAllData();
    }
  }, [loader]);

  return (
    <Layout>
      <Box
        sx={{ backgroundColor: colors.lightGray, p: 3, borderRadius: "12px" }}
      >
        <Grid
          container
          alignItems="center"
          justifyContent="space-between"
          sx={{ bgcolor: colors.white, p: 2, borderRadius: "12px" }}
        >
          <Grid item xs={6}>
            <Typography variant="body1" fontWeight="bold" color="initial">
              About Page Management
            </Typography>
          </Grid>
          <Grid
            item
            xs={6}
            sx={{ display: "flex", justifyContent: "flex-end" }}
          >
            <BreadCrumbs currentPage="About Page Management" />
          </Grid>
        </Grid>
        {loader ? (
          <LoadingCircle />
        ) : (
          <Box sx={{ mt: 2 }}>
            <ImageEditor
              title={"About Banner"}
              handleData={(e) =>
                submitAboutBanner("AboutBanner", e, aboutBanner)
              }
              uploadedImage={aboutBanner}
            />
            <TextEditor
              title="About PTN"
              characterLimit={540}
              data={aboutPtn}
              handleData={(e) => handleAboutPTN(e)}
            />
            <ImageEditor
              title={"Clipped Image"}
              handleData={(e) =>
                submitAboutBanner("ClippedImg", e, clippedImage)
              }
              uploadedImage={clippedImage}
            />
            <ImageEditor
              title={"First Image"}
              handleData={(e) => submitAboutBanner("FirstImg", e, firstImage)}
              uploadedImage={firstImage}
            />
            <ImageEditor
              title={"Second Image"}
              handleData={(e) => submitAboutBanner("SecondImg", e, secondImage)}
              uploadedImage={secondImage}
            />
            <GeneralContent updateMainLoader={() => setLoader(true)} />
          </Box>
        )}
      </Box>
    </Layout>
  );
}

export default AboutPTN;
