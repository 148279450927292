import { get, post } from "app/apis";
import BookingsRoute from "./BookingsRoute";

const BookingsServices = {
  getRentWarehouseSpace: async (params) => {
    const data = await get(BookingsRoute.getRentWarehouseSpace, params);
    return data;
  },
  getBookLandFreight: async (params) => {
    const data = await get(BookingsRoute.getBookLandFreight, params);
    return data;
  },
  getBookSeaFreight: async (params) => {
    const data = await get(BookingsRoute.getBookSeaFreight, params);
    return data;
  },
  getMoveYourCar: async (params) => {
    const data = await get(BookingsRoute.getMoveYourCar, params);
    return data;
  },
  updateLandStatus: async (obj) => {
    const data = await post(BookingsRoute.updateLandStatus, obj);
    return data;
  },
  updateSeaStatus: async (obj) => {
    const data = await post(BookingsRoute.updateSeaStatus, obj);
    return data;
  },
  updateMoveStatus: async (obj) => {
    const data = await post(BookingsRoute.updateMoveStatus, obj);
    return data;
  },
  updateRentStatus: async (obj) => {
    const data = await post(BookingsRoute.updateRentStatus, obj);
    return data;
  },
  uploadInvPodFor: async (formName, obj) => {
    const data = await post(BookingsRoute.uploadInvPodFor + formName, obj);
    return data;
  },
};

export default BookingsServices;
