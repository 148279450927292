import { get, post } from "app/apis";

const GeneralServices = {
  getBanner: async (routeName) => {
    const data = await get(`/get${routeName}`);
    return data;
  },
  createBanner: async (routeName, obj) => {
    const data = await post(`/create${routeName}`, obj);
    return data;
  },
  updateBanner: async (routeName, obj) => {
    const data = await post(`/update${routeName}`, obj);
    return data;
  },
};

export default GeneralServices;
