import { get, post } from "app/apis";
import ContactUsRoutes from "./ContactUsRoutes";

const ContactUsServices = {
  getContactUsMainText: async () => {
    const data = await get(ContactUsRoutes.getContactUsMainText);
    return data;
  },
  getLogisticsPartner: async () => {
    const data = await get(ContactUsRoutes.getLogisticsPartner);
    return data;
  },
  createContactUsMainText: async (obj) => {
    const data = await post(ContactUsRoutes.createContactUsMainText, obj);
    return data;
  },
  updateContactUsMainText: async (obj) => {
    const data = await post(ContactUsRoutes.updateContactUsMainText, obj);
    return data;
  },
};

export default ContactUsServices;
