import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Grid,
  IconButton,
  Pagination,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import Layout from "app/layout/Layout";
import colors from "app/styles/colors";
import BreadCrumbs from "app/components/Breadcrumbs/Breadcrumbs";
import { LoadingCircle } from "app/components/UI/Loader";
import { DeleteOutlineTwoTone } from "@mui/icons-material";
import AddJob from "app/components/Dialog/AddJob";
import CareerServices from "app/apis/Career/CareerServices";

function JobProposals() {
  const [loader, setLoader] = useState(true);
  const [showDialog, setShowDialog] = useState(false);
  const [jobList, setJobList] = useState([]);
  const [currentLinks, setCurrentLinks] = useState([]);

  const getAllJobs = async (pageNo) => {
    try {
      setLoader(true);
      let params = { page: pageNo ?? 1 };
      let res = await CareerServices.getCareerJobProposal(params);
      setCurrentLinks(res?.data?.meta?.links?.slice(1, -1));
      setJobList(res?.data?.data);
    } catch (error) {
      console.log("🚀 ~ getData ~ error:", error);
    } finally {
      setLoader(false);
    }
  };

  useEffect(() => {
    if (loader) {
      getAllJobs();
    }
  }, [loader]);

  return (
    <Layout>
      <AddJob
        handleClose={() => {
          setShowDialog(false);
        }}
        open={showDialog}
        reload={() => setLoader(true)}
        characterLimit={1500}
      />
      <Box
        sx={{ backgroundColor: colors.lightGray, p: 3, borderRadius: "12px" }}
      >
        <Grid
          container
          alignItems="center"
          justifyContent="space-between"
          sx={{ bgcolor: colors.white, p: 2, borderRadius: "12px" }}
        >
          <Grid item xs={6}>
            <Typography variant="body1" fontWeight="bold" color="initial">
              Job Proposals
            </Typography>
          </Grid>
          <Grid
            item
            xs={6}
            sx={{ display: "flex", justifyContent: "flex-end" }}
          >
            <BreadCrumbs currentPage="Job Proposals" />
          </Grid>
        </Grid>
        {loader ? (
          <LoadingCircle />
        ) : (
          <Box sx={{ my: 2 }}>
            <TableContainer component={Paper}>
              <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                  <TableRow sx={{ bgcolor: colors.primary }}>
                    <TableCell sx={{ fontWeight: "bold", color: colors.white }}>
                      Name
                    </TableCell>
                    <TableCell sx={{ fontWeight: "bold", color: colors.white }}>
                      Email
                    </TableCell>
                    <TableCell sx={{ fontWeight: "bold", color: colors.white }}>
                      Phone
                    </TableCell>
                    <TableCell sx={{ fontWeight: "bold", color: colors.white }}>
                      Cover Letter
                    </TableCell>
                    <TableCell sx={{ fontWeight: "bold", color: colors.white }}>
                      Action
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {jobList.map((row) => (
                    <TableRow
                      key={row.title}
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    >
                      <TableCell component="th" scope="row">
                        {row.name}
                      </TableCell>
                      <TableCell component="th" scope="row">
                        {row.email}
                      </TableCell>
                      <TableCell component="th" scope="row">
                        {row.phone}
                      </TableCell>
                      <TableCell component="th" scope="row">
                        {row.cover_letter}
                      </TableCell>
                      <TableCell>
                        <a href={row?.pdf_attachment} target="_blank" download>
                          <Button color="warning">View PDF</Button>
                        </a>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        )}
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Pagination
            variant="outlined"
            color="success"
            count={currentLinks?.length}
            onChange={(e, value) => getAllJobs(value)}
          />
        </Box>
      </Box>
    </Layout>
  );
}

export default JobProposals;
