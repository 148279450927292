import {
  AppSettingsAltTwoTone,
  BookOnlineTwoTone,
  CalendarMonthTwoTone,
  CircleTwoTone,
  ForwardToInboxTwoTone,
  LayersTwoTone,
  LogoutTwoTone,
  PointOfSaleTwoTone,
  ReceiptTwoTone,
  SupportAgentTwoTone,
  WorkTwoTone,
} from "@mui/icons-material";
import { BsSpeedometer2 } from "react-icons/bs";
import Images from "./assets/Images";

const Navigation = [
  {
    label: "Dashboard",
    icon: <BsSpeedometer2 />,
    type: "",
    path: "/",
  },
  {
    label: "Pages",
    icon: <LayersTwoTone />,
    type: "",
    childPath: ["/home"],
    children: [
      {
        label: "Home",
        icon: <CircleTwoTone />,
        path: "/home",
      },
      {
        label: "Freights",
        icon: <CircleTwoTone />,
        path: "/freights",
      },
      {
        label: "About PTN",
        icon: <CircleTwoTone />,
        path: "/aboutPTN",
      },
      {
        label: "Our Leadership",
        icon: <CircleTwoTone />,
        path: "/our-leadership",
      },
      {
        label: "Our Vision & Mission",
        icon: <CircleTwoTone />,
        path: "/our-vision-mission",
      },
      {
        label: "Our History",
        icon: <CircleTwoTone />,
        path: "/our-history",
      },
      {
        label: "Logistic Solutions",
        icon: <CircleTwoTone />,
        path: "/logistic-solutions",
      },
      {
        label: "Industries We Move",
        icon: <CircleTwoTone />,
        path: "/industries-we-move",
      },
      {
        label: "Warehouse & Distribution",
        icon: <CircleTwoTone />,
        path: "/warehousing-distribution",
      },
      {
        label: "Fleet Management Solutions",
        icon: <CircleTwoTone />,
        path: "/fleet-management",
      },
      {
        label: "Last Mile Solution",
        icon: <CircleTwoTone />,
        path: "/last-mile-solution",
      },
      {
        label: "Insights",
        icon: <CircleTwoTone />,
        path: "/insights",
      },
      {
        label: "Contact Us",
        icon: <CircleTwoTone />,
        path: "/contact-us",
      },
      {
        label: "Careers",
        icon: <WorkTwoTone />,
        path: "/careers",
      },
    ],
  },

  {
    label: "Bookings",
    icon: <BookOnlineTwoTone />,
    type: "",
    path: "/bookings",
  },
  {
    label: "App",
    icon: <AppSettingsAltTwoTone />,
    type: "",
    path: "",
  },
  {
    label: "Email",
    icon: <ForwardToInboxTwoTone />,
    type: "",
    path: "",
  },
  {
    label: "Job Proposals",
    icon: <WorkTwoTone />,
    type: "",
    path: "/job-proposals",
  },
  {
    label: "Contact Us",
    icon: <WorkTwoTone />,
    type: "",
    path: "/contact-us-request",
  },
  // {
  //   label: "POD",
  //   icon: <PointOfSaleTwoTone />,
  //   type: "",
  //   path: "",
  // },
];
export default Navigation;

export const freightsNavigation = [
  {
    icon: Images.landFreightIcon,
    title: "Land Freight",
    link: "/land-freight",
  },
  {
    icon: Images.shipFreightIcon,
    title: "Sea Freight",
    link: "/sea-freight",
  },
  {
    icon: Images.crossBorderFreightIcon,
    title: "Cross Border Freight",
    link: "/crossborder-freight",
  },
  {
    icon: Images.airFreightIcon,
    title: "Air Freight",
    link: "/air-freight",
  },
  {
    icon: Images.railFreightIcon,
    title: "Rail Freight",
    link: "/rail-freight",
  },
];

export const freightsBookingsNavigation = [
  {
    icon: Images.landFreightIcon,
    title: "Land Freight Bookings",
    link: "/landfreight-bookings",
  },
  {
    icon: Images.shipFreightIcon,
    title: "Sea Freight Bookings",
    link: "/seafreight-bookings",
  },
  {
    icon: Images.crossBorderFreightIcon,
    title: "Warehouse Bookings",
    link: "/warehouse-bookings",
  },
  {
    icon: Images.airFreightIcon,
    title: "Vehicle Bookings",
    link: "/vehicle-bookings",
  },
];
