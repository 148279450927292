import { get, post, patch, deleted } from "app/apis";
import OurLogisticsRoutes from "./OurLogisticsRoutes";

const OurLogisticsServices = {
  getLogCard: async () => {
    const data = await get(OurLogisticsRoutes.getLogCard);
    return data;
  },
  createLogCard: async (obj) => {
    const data = await post(OurLogisticsRoutes.createLogCard, obj);
    return data;
  },
  updateLogCard: async (obj) => {
    const data = await post(OurLogisticsRoutes.updateLogCard, obj);
    return data;
  },
  getMainText: async () => {
    const data = await get(OurLogisticsRoutes.getMainText);
    return data;
  },
  createMainText: async (obj) => {
    const data = await post(OurLogisticsRoutes.createMainText, obj);
    return data;
  },
  updateMainText: async (obj) => {
    const data = await post(OurLogisticsRoutes.updateMainText, obj);
    return data;
  },
  getSubText: async () => {
    const data = await get(OurLogisticsRoutes.getSubText);
    return data;
  },
  createSubText: async (obj) => {
    const data = await post(OurLogisticsRoutes.createSubText, obj);
    return data;
  },
  updateSubText: async (obj) => {
    const data = await post(OurLogisticsRoutes.updateSubText, obj);
    return data;
  },
};

export default OurLogisticsServices;
