import React, { useEffect, useRef, useState } from "react";
import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  Radio,
  RadioGroup,
  Typography,
} from "@mui/material";
import { CloseRounded } from "@mui/icons-material";
import useAuth from "app/hooks/useAuth";
import { ErrorToaster, SuccessToaster } from "../Toaster/Toaster";
import { InputField, PhoneInputField } from "../UI/TextField";
import { emailRegex } from "app/utils";
import SelectField from "../UI/SelectFields";
import { PrimaryButton } from "../Buttons/Button";
import { Error } from "../UI/Error";
import { useForm } from "react-hook-form";
import colors from "app/styles/colors";
import Images from "app/assets/Images";
import LeaderShipServices from "app/apis/LeaderShip/LeaderShipServices";

function AddNewLeader({ handleClose, open, toggle, handleSubmitFunc }) {
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    reset,
  } = useForm();

  const [imageFile, setImageFile] = useState("");

  const handleSubmitEmp = async (data) => {
    try {
      await LeaderShipServices.createLeader(data);
      SuccessToaster("Leader added successfully.");
      formReset();
      handleSubmitFunc();
    } catch (error) {
      ErrorToaster(error);
    }
  };

  // *For add employee
  const addEmployeeData = async (data) => {
    let formData = new FormData();
    formData.append("name", data.employeeName);
    formData.append("designation", data.designation);
    if (imageFile) {
      formData.append("image", imageFile, imageFile?.name);
    }
    handleSubmitEmp(formData);
  };

  const uploadImage = async (e) => {
    try {
      if (e.target.files) {
        setImageFile(e.target.files[0]);
      }
    } catch (error) {
      console.log(
        "🚀 ~ file: AddNewLeader.js:57 ~ uploadImage ~ error:",
        error
      );
    }
  };

  const formReset = () => {
    setImageFile("");
    reset();
  };
  return (
    <Dialog
      open={open}
      fullWidth
      onClose={() => {
        handleClose();
        formReset();
      }}
      sx={{ "& .MuiDialog-paper": { borderRadius: "12px" } }}
    >
      <DialogTitle
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        {toggle ? "Edit Leadership" : "Add New Leadership"}
        <IconButton
          onClick={() => {
            handleClose();
            formReset();
          }}
        >
          <CloseRounded />
        </IconButton>
      </DialogTitle>
      <Divider />
      <DialogContent>
        <Typography component={"form"} onSubmit={handleSubmit(addEmployeeData)}>
          <Grid container alignItems="center">
            {/* Full Name */}
            <Grid item xs={12}>
              <InputField
                label="Full Name"
                error={errors?.employeeName?.message}
                register={register("employeeName", {
                  required: "Please enter employee name.",
                })}
              />
            </Grid>

            {/* Address */}
            <Grid item xs={12}>
              <InputField
                label="Designation"
                error={errors?.designation?.message}
                register={register("designation", {
                  required: "Please enter designation.",
                })}
              />
            </Grid>

            <Grid item xs={12}>
              <FormControl fullWidth sx={{ my: 0.5 }}>
                <Box
                  sx={{
                    border: `1px dashed ${colors.lightGray}`,
                    textAlign: "center",
                    height: 300,
                    position: "relative",
                    overflow: "hidden",
                    p: 1,
                  }}
                >
                  <label htmlFor={"leaderImage"}>
                    <img
                      src={
                        imageFile
                          ? URL.createObjectURL(imageFile)
                          : Images.profilePlaceholder
                      }
                      style={{
                        objectFit: "contain",
                        width: "100%",
                        height: 300,
                      }}
                    />
                  </label>
                  <input
                    style={{ display: "none" }}
                    id={"leaderImage"}
                    type="file"
                    onChange={(e) => uploadImage(e)}
                    accept="image/*"
                  />
                </Box>
              </FormControl>
            </Grid>
          </Grid>

          <PrimaryButton
            type="submit"
            // loading={loading}
            myStyle={{ mt: 1, borderRadius: "12px" }}
            fullWidth={true}
            title="Save"
          />
        </Typography>
      </DialogContent>
    </Dialog>
  );
}

export default AddNewLeader;
