// PTN pages
import AboutPTN from "app/views/AboutPTN";
import Home from "app/views/Home/Home";
import OurHistory from "app/views/OurHistory";
import OurLeadership from "app/views/OurLeadership";
import OurVision from "app/views/OurVision";
import LogisticsSolution from "app/views/LogisticsSolution";
import IndustryWeMove from "app/views/IndustryWeMove";
import ContactUs from "app/views/ContactUs";
import IndustryWeMoveCard from "app/views/IndustryWeMove/IndustryWeMoveCard";
import Freights from "app/views/Freights";
import LandFreight from "app/views/Freights/FreightPages/LandFreight";
import SeaFreight from "app/views/Freights/FreightPages/SeaFreight";
import CrossBorderFreight from "app/views/Freights/FreightPages/CrossBorderFreight";
import AirFreight from "app/views/Freights/FreightPages/AirFreight";
import RailFreight from "app/views/Freights/FreightPages/RailFreight";
import WarehoueDistribution from "app/views/WarehoueDistribution";
import FleetManagement from "app/views/FleetManagement";
import LastMileSolutions from "app/views/LastMileSolutions";
import InsightsCard from "app/views/Insights/InsightsCard";
import Insights from "app/views/Insights";
import Bookings from "app/views/Bookings";
import WarehouseBookings from "app/views/Bookings/BookingPages/warehouseBookings";
import LandFreightBookings from "app/views/Bookings/BookingPages/landFreightBookings";
import SeaFreightBookings from "app/views/Bookings/BookingPages/seaFreightBookings";
import VehicleBookings from "app/views/Bookings/BookingPages/vehicleBookings";
import Careers from "app/views/Careers";
import JobProposals from "app/views/JobProposals";
import ContactListView from "app/views/ContactUs/ContactListView";

const AdminRoutes = [
  {
    path: "/home",
    component: <Home />,
  },
  {
    path: "/aboutPTN",
    component: <AboutPTN />,
  },
  {
    path: "/our-leadership",
    component: <OurLeadership />,
  },
  {
    path: "/our-vision-mission",
    component: <OurVision />,
  },
  {
    path: "/our-history",
    component: <OurHistory />,
  },
  {
    path: "/logistic-solutions",
    component: <LogisticsSolution />,
  },
  {
    path: "/industries-we-move",
    component: <IndustryWeMove />,
  },
  {
    path: "/industries-we-move/:id",
    component: <IndustryWeMoveCard />,
  },
  {
    path: "/contact-us",
    component: <ContactUs />,
  },
  {
    path: "/careers",
    component: <Careers />,
  },
  {
    path: "/job-proposals",
    component: <JobProposals />,
  },
  {
    path: "/contact-us-request",
    component: <ContactListView />,
  },
  {
    path: "/warehousing-distribution",
    component: <WarehoueDistribution />,
  },
  {
    path: "/fleet-management",
    component: <FleetManagement />,
  },
  {
    path: "/last-mile-solution",
    component: <LastMileSolutions />,
  },
  {
    path: "/insights",
    component: <Insights />,
  },
  {
    path: "/insights/:id",
    component: <InsightsCard />,
  },
  {
    path: "/freights",
    component: <Freights />,
  },
  // Freights Pages ================
  {
    path: "/land-freight",
    component: <LandFreight />,
  },
  {
    path: "/sea-freight",
    component: <SeaFreight />,
  },
  {
    path: "/crossborder-freight",
    component: <CrossBorderFreight />,
  },
  {
    path: "/air-freight",
    component: <AirFreight />,
  },
  {
    path: "/rail-freight",
    component: <RailFreight />,
  },
  {
    path: "/bookings",
    component: <Bookings />,
  },
  {
    path: "/warehouse-bookings",
    component: <WarehouseBookings />,
  },
  {
    path: "/landfreight-bookings",
    component: <LandFreightBookings />,
  },
  {
    path: "/seafreight-bookings",
    component: <SeaFreightBookings />,
  },
  {
    path: "/vehicle-bookings",
    component: <VehicleBookings />,
  },
];

export default AdminRoutes;
