import React, { useEffect, useState } from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Grid,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import Layout from "app/layout/Layout";
import colors from "app/styles/colors";
import BreadCrumbs from "app/components/Breadcrumbs/Breadcrumbs";
import {
  DeleteOutlineTwoTone,
  EditTwoTone,
  ExpandMore,
} from "@mui/icons-material";
import { PrimaryButton, SecondaryButton } from "app/components/Buttons/Button";
import { LoadingCircle } from "app/components/UI/Loader";
import WhyPTNDialog from "app/components/Dialog/WhyPTNDialog";
import ImageEditorCombine from "app/components/Editors/ImageEditorCombine";
import TextEditorCombine from "app/components/Editors/TextEditorCombine";
import { useNavigate } from "react-router-dom";
import { checkCharacterValidation, checkImageValidation } from "app/utils";
import WarehouseServices from "app/apis/Warehouse/WarehouseServices";
import { ErrorToaster, SuccessToaster } from "app/components/Toaster/Toaster";

let characterLength = {
  mainContent: 460,
  topContent: 280,
  subContent: 230,
};

function WarehoueDistribution() {
  const [showDialog, setShowDialog] = useState(false);
  const [allImages, setAllImages] = useState({});
  const [allContents, setAllContents] = useState({});
  const [itemData, setItemData] = useState({});
  const [ptnCardList, setPtnCardList] = useState([]);
  const [loader, setLoader] = useState(true);
  const [selectedItem, setSelectedItem] = useState({});

  const handleDataSubmission = async () => {
    try {
      if (
        checkCharacterValidation(
          characterLength.mainContent,
          allContents?.mainContent?.length,
          "Main Content"
        ) &&
        checkCharacterValidation(
          characterLength.topContent,
          allContents?.whyPtnText?.length,
          "Why PTN Content"
        ) &&
        checkCharacterValidation(
          characterLength.subContent,
          allContents?.firstSectionText?.length,
          "First Section Content"
        ) &&
        checkCharacterValidation(
          characterLength.subContent,
          allContents?.secondSectionText?.length,
          "Second Section Content"
        ) &&
        checkImageValidation(allImages?.bannerImage, "Banner Image") &&
        checkImageValidation(allImages?.firstImage, "First Section Image") &&
        checkImageValidation(
          allImages?.secondSectionImage,
          "Second Section Image"
        )
      ) {
        let formData = new FormData();
        {
          itemData?.id && formData.append("id", itemData?.id);
        }
        formData.append("description", allContents?.mainContent?.text);
        formData.append("why_ptn", allContents?.mainContent?.text);
        formData.append("why_ptn", allContents?.whyPtnText?.text);
        formData.append(
          "section_first_description",
          allContents?.firstSectionText?.text
        );
        formData.append(
          "section_second_description",
          allContents?.secondSectionText?.text
        );

        if (typeof allImages?.bannerImage == "object") {
          formData.append(
            "banner_image",
            allImages?.bannerImage,
            allImages?.bannerImage?.name
          );
        }
        if (typeof allImages?.firstImage == "object") {
          formData.append(
            "section_first_image",
            allImages?.firstImage,
            allImages?.firstImage?.name
          );
        }
        if (typeof allImages?.secondSectionImage == "object") {
          formData.append(
            "section_second_image",
            allImages?.secondSectionImage,
            allImages?.secondSectionImage?.name
          );
        }

        if (itemData?.id) {
          await WarehouseServices.updateWarehouse(formData);
        } else {
          await WarehouseServices.createWarehouse(formData);
        }
        setLoader(true);
      }
    } catch (error) {
      ErrorToaster(error);
      console.log("🚀 ~ handleDataSubmission ~ error:", error);
    }
  };

  const handlePtnCardSubmission = async (data, id) => {
    try {
      if (id) {
        await WarehouseServices.updatePtnCard(data);
      } else {
        await WarehouseServices.createPtnCard(data);
      }
      SuccessToaster("Success");
      setLoader(true);
    } catch (error) {
      console.log("🚀 ~ handlePtnCardSubmission ~ error:", error);
      ErrorToaster("Something went wrong.");
    }
  };

  const handleDeleteItem = async (id) => {
    await WarehouseServices.deletePtnCard({ id });
    setLoader(true);
  };

  const getItemData = async () => {
    let res = await WarehouseServices.getWarehouse();
    let ptnCards = await WarehouseServices.getPtnCard();
    let data = res?.data[0];
    setPtnCardList(ptnCards?.data);
    setItemData(data);
    setAllContents({
      mainContent: {
        text: data?.description,
        length: data?.description?.length,
      },
      whyPtnText: {
        text: data?.why_ptn,
        length: data?.why_ptn?.length,
      },
      firstSectionText: {
        text: data?.section_first_description,
        length: data?.section_first_description?.length,
      },
      secondSectionText: {
        text: data?.section_second_description,
        length: data?.section_second_description?.length,
      },
    });
    setAllImages({
      bannerImage: data?.banner_image,
      firstImage: data?.section_first_image,
      secondSectionImage: data?.section_second_image,
    });
    setLoader(false);
  };

  useEffect(() => {
    if (loader) {
      getItemData();
    }
  }, [loader]);

  return (
    <Layout>
      <WhyPTNDialog
        handleClose={() => {
          setShowDialog(false);
          setSelectedItem({});
        }}
        open={showDialog}
        characterLimit={106}
        selectedItem={selectedItem}
        handleSubmitFunc={(e, id) => handlePtnCardSubmission(e, id)}
      />
      <Box
        sx={{ backgroundColor: colors.lightGray, p: 3, borderRadius: "12px" }}
      >
        <Grid
          container
          alignItems="center"
          justifyContent="space-between"
          sx={{ bgcolor: colors.white, p: 2, borderRadius: "12px" }}
        >
          <Grid item xs={6}>
            <Typography variant="body1" fontWeight="bold" color="initial">
              Warehousing & Distribution
            </Typography>
          </Grid>
          <Grid
            item
            xs={6}
            sx={{ display: "flex", justifyContent: "flex-end" }}
          >
            <BreadCrumbs currentPage="Warehousing & Distribution" />
          </Grid>
        </Grid>
        {loader ? (
          <LoadingCircle />
        ) : (
          <Box sx={{ mt: 2 }}>
            <ImageEditorCombine
              title={"Banner Image"}
              handleData={(e) => setAllImages({ ...allImages, bannerImage: e })}
              uploadedImage={allImages?.bannerImage}
            />
            <TextEditorCombine
              title="Main Content"
              characterLimit={characterLength.mainContent}
              data={itemData?.description}
              handleData={(e, contentLength) =>
                setAllContents({
                  ...allContents,
                  mainContent: {
                    text: e,
                    length: contentLength,
                  },
                })
              }
            />
            <TextEditorCombine
              title="Why PTN"
              characterLimit={characterLength.topContent}
              data={itemData?.why_ptn}
              handleData={(e, contentLength) =>
                setAllContents({
                  ...allContents,
                  whyPtnText: {
                    text: e,
                    length: contentLength,
                  },
                })
              }
            />
            <TextEditorCombine
              title="First Secion Text"
              characterLimit={characterLength.subContent}
              data={itemData?.section_first_description}
              handleData={(e, contentLength) =>
                setAllContents({
                  ...allContents,
                  firstSectionText: {
                    text: e,
                    length: contentLength,
                  },
                })
              }
            />
            <ImageEditorCombine
              title={"First Secion Image"}
              handleData={(e) => setAllImages({ ...allImages, firstImage: e })}
              uploadedImage={allImages?.firstImage}
            />
            <TextEditorCombine
              title="Second Section Content"
              characterLimit={characterLength.subContent}
              data={itemData?.section_second_description}
              handleData={(e, contentLength) =>
                setAllContents({
                  ...allContents,
                  secondSectionText: {
                    text: e,
                    length: contentLength,
                  },
                })
              }
            />
            <ImageEditorCombine
              title={"Second Section Image"}
              handleData={(e) =>
                setAllImages({ ...allImages, secondSectionImage: e })
              }
              uploadedImage={allImages?.secondSectionImage}
            />
            <PrimaryButton
              title="Save"
              onClick={() => handleDataSubmission()}
              style={{
                margin: "10px 0px",
              }}
            />
            <Accordion
              sx={{
                mb: 1,
                boxShadow: "none",
                "&::before": {
                  display: "none",
                },
              }}
            >
              <AccordionSummary expandIcon={<ExpandMore />}>
                <Typography variant="body1" fontWeight="bold" color="initial">
                  Why PTN Cards
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "flex-end",
                    p: 2,
                  }}
                >
                  <SecondaryButton
                    onClick={() => setShowDialog(!showDialog)}
                    title="Add New Item"
                  />
                </Box>
                <TableContainer component={Paper}>
                  <Table sx={{ minWidth: 650 }} aria-label="simple table">
                    <TableHead>
                      <TableRow sx={{ bgcolor: colors.primary }}>
                        <TableCell
                          sx={{ fontWeight: "bold", color: colors.white }}
                        >
                          Image
                        </TableCell>
                        <TableCell
                          sx={{ fontWeight: "bold", color: colors.white }}
                        >
                          Title
                        </TableCell>
                        <TableCell
                          sx={{ fontWeight: "bold", color: colors.white }}
                        >
                          Actions
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {ptnCardList?.map((row) => (
                        <TableRow
                          key={row.id}
                          sx={{
                            "&:last-child td, &:last-child th": { border: 0 },
                          }}
                        >
                          <TableCell component="th" scope="row">
                            <Typography
                              component={"img"}
                              sx={{
                                width: 70,
                                height: 70,
                                objectFit: "contain",
                                borderRadius: 70,
                              }}
                              src={row?.image}
                            />
                          </TableCell>
                          <TableCell component="th" scope="row">
                            {row.title}
                          </TableCell>
                          <TableCell>
                            <IconButton
                              onClick={() => handleDeleteItem(row?.id)}
                            >
                              <DeleteOutlineTwoTone color="error" />
                            </IconButton>
                            <IconButton
                              onClick={() => {
                                setSelectedItem(row);
                                setShowDialog(true);
                              }}
                            >
                              <EditTwoTone color="primary" />
                            </IconButton>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </AccordionDetails>
            </Accordion>
          </Box>
        )}
      </Box>
    </Layout>
  );
}

export default WarehoueDistribution;
