import Dashboard from "app/views/Dashboard/Dashboard";
import { Navigate } from "react-router-dom";

const AppRoutes = [
  {
    path: "*",
    component: <Navigate to="/" />,
  },
  {
    path: "/",
    component: <Dashboard />,
  },
];

export default AppRoutes;
