import React, { useEffect, useState } from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Grid,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import Layout from "app/layout/Layout";
import colors from "app/styles/colors";
import BreadCrumbs from "app/components/Breadcrumbs/Breadcrumbs";
import ImageEditor from "../../components/Editors/ImageEditor";
import {
  ChromeReaderMode,
  DeleteTwoTone,
  EditTwoTone,
  ExpandMore,
} from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import { SecondaryButton } from "app/components/Buttons/Button";
import { LoadingCircle } from "app/components/UI/Loader";
import GeneralServices from "app/apis/General/GeneralServices";
import { ErrorToaster, SuccessToaster } from "app/components/Toaster/Toaster";
import AddInsightsItem from "app/components/Dialog/AddInsightsItem";
import insightsServices from "app/apis/Insights/InsightsServices";

function Insights() {
  const navigate = useNavigate();
  const [showDialog, setShowDialog] = useState(false);
  const [toggleStatus, setToggleStatus] = useState(false);
  const [loader, setLoader] = useState(true);
  const [mainBanner, setMainBanner] = useState("");
  const [insightsItems, setInsightsItems] = useState([]);
  const [selectedItem, setSelectedItem] = useState({});

  const submitBanner = async (route, data, oldData) => {
    try {
      let formData = new FormData();
      {
        oldData?.id && formData.append("id", oldData?.id);
      }
      formData.append("image", data, data?.name);
      let result = "";
      if (oldData) {
        result = await GeneralServices.updateBanner(route, formData);
      } else {
        result = await GeneralServices.createBanner(route, formData);
      }
      SuccessToaster(result?.message);
      setLoader(true);
    } catch (error) {
      ErrorToaster(error);
      console.log("🚀 ~ submitBanner ~ error:", error);
    }
  };

  const handleDeleteItem = async (itemId) => {
    try {
      await insightsServices.deleteInsightItem({ id: itemId });
    } catch (error) {
      console.log("🚀 ~ handleDeleteItem ~ error:", error);
    } finally {
      setLoader(true);
    }
  };

  // get apis ===========
  const getAllImages = async () => {
    try {
      let result = await GeneralServices.getBanner("InsightBanner");
      setMainBanner(result?.data[0]);
    } catch (error) {
      console.log("🚀 ~ getAllImages ~ error:", error);
    }
  };

  const getAllData = async () => {
    try {
      await getAllImages();
      let result = await insightsServices.getInsightItem();
      setInsightsItems(result?.data);
    } catch (error) {
      console.log("🚀 ~ getAllData ~ error:", error);
    } finally {
      setLoader(false);
    }
  };

  useEffect(() => {
    if (loader) {
      getAllData();
    }
  }, [loader]);
  return (
    <Layout>
      <AddInsightsItem
        showTitle
        handleClose={() => {
          setShowDialog(false);
          setSelectedItem({});
        }}
        open={showDialog}
        characterLimit={110}
        toggle={toggleStatus}
        selectedData={selectedItem}
        reload={() => setLoader(true)}
      />
      <Box
        sx={{ backgroundColor: colors.lightGray, p: 3, borderRadius: "12px" }}
      >
        <Grid
          container
          alignItems="center"
          justifyContent="space-between"
          sx={{ bgcolor: colors.white, p: 2, borderRadius: "12px" }}
        >
          <Grid item xs={6}>
            <Typography variant="body1" fontWeight="bold" color="initial">
              Insights
            </Typography>
          </Grid>
          <Grid
            item
            xs={6}
            sx={{ display: "flex", justifyContent: "flex-end" }}
          >
            <BreadCrumbs currentPage="Insights" />
          </Grid>
        </Grid>
        {loader ? (
          <LoadingCircle />
        ) : (
          <Box sx={{ mt: 2 }}>
            <ImageEditor
              title={"Banner Image"}
              handleData={(e) => submitBanner("InsightBanner", e, mainBanner)}
              uploadedImage={mainBanner}
            />
            {/* ============Table============ */}
            <Accordion
              sx={{
                mb: 1,
                boxShadow: "none",
                "&::before": {
                  display: "none",
                },
              }}
            >
              <AccordionSummary expandIcon={<ExpandMore />}>
                <Typography variant="body1" fontWeight="bold" color="initial">
                  Insights Items
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "flex-end",
                    p: 2,
                  }}
                >
                  <SecondaryButton
                    onClick={() => {
                      setShowDialog(true);
                      setToggleStatus(true);
                    }}
                    title="Add Item"
                  />
                </Box>
                <TableContainer component={Paper}>
                  <Table sx={{ minWidth: 650 }} aria-label="simple table">
                    <TableHead>
                      <TableRow sx={{ bgcolor: colors.primary }}>
                        <TableCell
                          sx={{ fontWeight: "bold", color: colors.white }}
                        >
                          Image
                        </TableCell>
                        <TableCell
                          sx={{ fontWeight: "bold", color: colors.white }}
                        >
                          Title
                        </TableCell>
                        <TableCell
                          sx={{ fontWeight: "bold", color: colors.white }}
                        >
                          Action
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {insightsItems?.map((row) => (
                        <TableRow
                          key={row.name}
                          sx={{
                            "&:last-child td, &:last-child th": { border: 0 },
                          }}
                        >
                          <TableCell component="th" scope="row">
                            <Typography
                              component={"img"}
                              sx={{
                                width: 70,
                                height: 70,
                                objectFit: "contain",
                                borderRadius: 70,
                              }}
                              src={row?.image}
                            />
                          </TableCell>
                          <TableCell component="th" scope="row">
                            {row.title}
                          </TableCell>
                          <TableCell>
                            <IconButton
                              onClick={() => {
                                setSelectedItem(row);
                                setShowDialog(true);
                                setToggleStatus(false);
                              }}
                            >
                              <EditTwoTone color="primary" />
                            </IconButton>
                            <IconButton
                              onClick={() => navigate(`/insights/${row?.id}`)}
                            >
                              <ChromeReaderMode color="info" />
                            </IconButton>
                            <IconButton
                              onClick={() => handleDeleteItem(row?.id)}
                            >
                              <DeleteTwoTone color="error" />
                            </IconButton>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </AccordionDetails>
            </Accordion>
          </Box>
        )}
      </Box>
    </Layout>
  );
}

export default Insights;
