import { get, post, patch, deleted } from "app/apis";
import lastMileSolutionRoutes from "./LastMileSolutionRoutes";

const lastMileSolutionServices = {
  createFleetManagement: async (obj) => {
    const data = await post(lastMileSolutionRoutes.createFleetManagement, obj);
    return data;
  },
  getFleetManagement: async () => {
    const data = await get(lastMileSolutionRoutes.getFleetManagement);
    return data;
  },
  updateFleetManagement: async (obj) => {
    const data = await post(lastMileSolutionRoutes.updateFleetManagement, obj);
    return data;
  },
};

export default lastMileSolutionServices;
