import { get, post, patch, deleted } from "app/apis";
import AuthRoutes from "./Auth.Routes";

const AuthServices = {
  login: async (obj) => {
    const data = await post(AuthRoutes.login, obj);
    return data;
  },
};

export default AuthServices;
