import React, { useEffect, useState } from "react";
import { Box, Grid, Typography } from "@mui/material";
import Layout from "app/layout/Layout";
import colors from "app/styles/colors";
import BreadCrumbs from "app/components/Breadcrumbs/Breadcrumbs";

import { LoadingCircle } from "app/components/UI/Loader";
import ImageEditorCombine from "app/components/Editors/ImageEditorCombine";
import TextEditorCombine from "app/components/Editors/TextEditorCombine";
import { PrimaryButton } from "app/components/Buttons/Button";
import lastMileSolutionServices from "app/apis/LastMileSolution/LastMileSolutionServices";
import { checkCharacterValidation, checkImageValidation } from "app/utils";
import { ErrorToaster } from "app/components/Toaster/Toaster";

let characterLength = {
  mainContent: 1400,
  subContent: 870,
};

function LastMileSolutions() {
  const [allImages, setAllImages] = useState({});
  const [allContents, setAllContents] = useState({});
  const [itemData, setItemData] = useState({});
  const [loader, setLoader] = useState(true);

  const handleDataSubmission = async () => {
    try {
      if (
        checkCharacterValidation(
          characterLength.mainContent,
          allContents?.firstText?.length,
          "First Section Content"
        ) &&
        checkCharacterValidation(
          characterLength.subContent,
          allContents?.secondText?.length,
          "Second Section Content"
        ) &&
        checkImageValidation(allImages?.bannerImage, "Banner Image") &&
        checkImageValidation(allImages?.firstImage, "First Section Image")
      ) {
        let formData = new FormData();
        {
          itemData?.id && formData.append("id", itemData?.id);
        }

        formData.append(
          "section_first_description",
          allContents?.firstText?.text
        );
        formData.append(
          "section_second_description",
          allContents?.secondText?.text
        );

        if (typeof allImages?.bannerImage == "object") {
          formData.append(
            "banner_image",
            allImages?.bannerImage,
            allImages?.bannerImage?.name
          );
        }
        if (typeof allImages?.firstImage == "object") {
          formData.append(
            "first_image",
            allImages?.firstImage,
            allImages?.firstImage?.name
          );
        }

        if (itemData?.id) {
          await lastMileSolutionServices.updateFleetManagement(formData);
        } else {
          await lastMileSolutionServices.createFleetManagement(formData);
        }
        setLoader(true);
      }
    } catch (error) {
      ErrorToaster(error);
      console.log("🚀 ~ handleDataSubmission ~ error:", error);
    }
  };

  const getItemData = async () => {
    let res = await lastMileSolutionServices.getFleetManagement();
    let data = res?.data[0];
    setItemData(data);
    setAllContents({
      firstText: {
        text: data?.section_first_description,
        length: data?.section_first_description?.length,
      },
      secondText: {
        text: data?.section_second_description,
        length: data?.section_second_description?.length,
      },
    });
    setAllImages({
      bannerImage: data?.banner_image,
      firstImage: data?.first_image,
    });
    setLoader(false);
  };

  useEffect(() => {
    if (loader) {
      getItemData();
    }
  }, [loader]);

  return (
    <Layout>
      <Box
        sx={{ backgroundColor: colors.lightGray, p: 3, borderRadius: "12px" }}
      >
        <Grid
          container
          alignItems="center"
          justifyContent="space-between"
          sx={{ bgcolor: colors.white, p: 2, borderRadius: "12px" }}
        >
          <Grid item xs={6}>
            <Typography variant="body1" fontWeight="bold" color="initial">
              Last Mile Solution
            </Typography>
          </Grid>
          <Grid
            item
            xs={6}
            sx={{ display: "flex", justifyContent: "flex-end" }}
          >
            <BreadCrumbs currentPage="Last Mile Solution" />
          </Grid>
        </Grid>
        {loader ? (
          <LoadingCircle />
        ) : (
          <Box sx={{ mt: 2 }}>
            <ImageEditorCombine
              title={"Banner Image"}
              handleData={(e) => setAllImages({ ...allImages, bannerImage: e })}
              uploadedImage={allImages?.bannerImage}
            />
            <TextEditorCombine
              title="First Secion Text"
              characterLimit={characterLength.mainContent}
              data={itemData?.section_first_description}
              handleData={(e, contentLength) =>
                setAllContents({
                  ...allContents,
                  firstText: {
                    text: e,
                    length: contentLength,
                  },
                })
              }
            />
            <ImageEditorCombine
              title={"First Image"}
              handleData={(e) => setAllImages({ ...allImages, firstImage: e })}
              uploadedImage={allImages?.firstImage}
            />
            <TextEditorCombine
              title="Second Secion Text"
              characterLimit={characterLength.subContent}
              data={itemData?.section_second_description}
              handleData={(e, contentLength) =>
                setAllContents({
                  ...allContents,
                  secondText: {
                    text: e,
                    length: contentLength,
                  },
                })
              }
            />
            <PrimaryButton
              title="Save"
              onClick={() => handleDataSubmission()}
              style={{
                margin: "10px 0px",
              }}
            />
          </Box>
        )}
      </Box>
    </Layout>
  );
}

export default LastMileSolutions;
