import React, { Fragment } from 'react'
import { Box, Typography } from '@mui/material'

import colors from 'app/styles/colors'

function PrimaryCard({ icon, name, number, color, bgColor, circle1, circle2 }) {
  return (
    <Box
      sx={{
        position: "relative",
        overflow: "hidden",
        backgroundColor: bgColor,
        borderRadius: "12px",
        px: 3,
        py: 5.5,
        cursor: "pointer",
      }}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "flex-start",
          justifyContent: "space-between"
        }}
      >
        <Box sx={{ zIndex: 999 }}>
          <Box sx={{ display: "flex", alignItems: "center", mt: 2, mb: 1 }}>
            <Typography
              variant="h3"
              sx={{ fontWeight: "bold", color: colors.white, mr: 1 }}
            >
              {number}
            </Typography>
          </Box>
          <Box>
            <Typography
              variant="body1"
              sx={{ color: color }}
            >
              {name}
            </Typography>
          </Box>
        </Box>
        {icon}
      </Box>
      <Box
        sx={{
          position: "absolute",
          top: -60,
          right: -70,
          zIndex: 1,
          backgroundColor: circle1,
          borderRadius: "50%",
          width: 200,
          height: 200,
        }}
      />
      <Box
        sx={{
          position: "absolute",
          top: -110,
          right: 20,
          backgroundColor: circle2,
          borderRadius: "50%",
          width: 200,
          height: 200,
        }}
      />
    </Box>
  )
}

export default PrimaryCard