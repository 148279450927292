import { get, post, patch, deleted } from "app/apis";
import IndustryWeMoveRoutes from "./IndustryWeMoveRoutes";

const IndustryWeMoveServices = {
  getMainText: async () => {
    const data = await get(IndustryWeMoveRoutes.getIndustryMainText);
    return data;
  },
  createMainText: async (obj) => {
    const data = await post(IndustryWeMoveRoutes.createIndustryMainText, obj);
    return data;
  },
  updateMainText: async (obj) => {
    const data = await post(IndustryWeMoveRoutes.updateIndustryMainText, obj);
    return data;
  },
  createIndustryItem: async (obj) => {
    const data = await post(IndustryWeMoveRoutes.createIndustryItem, obj);
    return data;
  },
  getIndustryItem: async () => {
    const data = await get(IndustryWeMoveRoutes.getIndustryItem);
    return data;
  },
  updateIndustryItem: async (obj) => {
    const data = await post(IndustryWeMoveRoutes.updateIndustryItem, obj);
    return data;
  },
  deleteIndustryItem: async (obj) => {
    const data = await post(IndustryWeMoveRoutes.deleteIndustryItem, obj);
    return data;
  },

  createIndustryItemInfo: async (obj) => {
    const data = await post(IndustryWeMoveRoutes.createIndustryItemInfo, obj);
    return data;
  },
  getIndustryItemInfo: async (id) => {
    const data = await get(IndustryWeMoveRoutes.getIndustryItemInfo + `/${id}`);
    return data;
  },
  updateIndustryItemInfo: async (obj) => {
    const data = await post(IndustryWeMoveRoutes.updateIndustryItemInfo, obj);
    return data;
  },
};

export default IndustryWeMoveServices;
