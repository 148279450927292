const AboutRoutes = {
  createAboutBanner: "/createAboutBanner",
  getAboutBanner: "/getAboutBanner",
  updateAbouBanner: "/updateAbouBanner",

  createAboutPtn: "/createAboutPtn",
  getAboutPtn: "/getAboutPtn",
  updateAboutPtn: "/updateAboutPtn",

  createGeneralContent: "/createGeneralContent",
  updateGeneralContent: "/updateGeneralContent",
  getGeneralContent: "/getGeneralContent",
};

export default AboutRoutes;
