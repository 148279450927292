import React, { useEffect, useState } from "react";
import { Box, Grid, Typography } from "@mui/material";
import Layout from "app/layout/Layout";
import colors from "app/styles/colors";
import ImageEditorCombine from "app/components/Editors/ImageEditorCombine";
import TextEditorCombine from "app/components/Editors/TextEditorCombine";
import { PrimaryButton } from "app/components/Buttons/Button";
import { checkCharacterValidation, checkImageValidation } from "app/utils";
import { ErrorToaster } from "app/components/Toaster/Toaster";
import { LoadingCircle } from "app/components/UI/Loader";
import FreightsServices from "app/apis/Freights/FreightsServices";

let characterLength = {
  mainContent: 450,
  subContent: 330,
};

function CrossBorderFreight() {
  const [allImages, setAllImages] = useState({});
  const [allContents, setAllContents] = useState({});
  const [itemData, setItemData] = useState({});
  const [loader, setLoader] = useState(true);

  const handleDataSubmission = async () => {
    try {
      if (
        checkCharacterValidation(
          characterLength.subContent,
          allContents?.mainContent?.length,
          "Main Content"
        ) &&
        checkCharacterValidation(
          characterLength.mainContent,
          allContents?.sectionContent?.length,
          "Section Content"
        ) &&
        checkCharacterValidation(
          characterLength.subContent,
          allContents?.firstKeyBenefit?.length,
          "First Key Benefit Content"
        ) &&
        checkCharacterValidation(
          characterLength.subContent,
          allContents?.secondKeyBenefit?.length,
          "Second Key Benefit Content"
        ) &&
        checkImageValidation(allImages?.bannerImage, "Main Banner") &&
        checkImageValidation(allImages?.sectionImage, "Section") &&
        checkImageValidation(
          allImages?.firstKeyBenefitImage,
          "First benefit"
        ) &&
        checkImageValidation(allImages?.secondKeyBenefitImage, "Second benefit")
      ) {
        let formData = new FormData();
        {
          itemData?.id && formData.append("id", itemData?.id);
        }

        formData.append("description", allContents?.mainContent?.text);
        formData.append(
          "section_description",
          allContents?.sectionContent?.text
        );
        formData.append(
          "benefit_first_description",
          allContents?.firstKeyBenefit?.text
        );
        formData.append(
          "benefit_second_description",
          allContents?.secondKeyBenefit?.text
        );

        if (typeof allImages?.bannerImage == "object") {
          formData.append(
            "banner_image",
            allImages?.bannerImage,
            allImages?.bannerImage?.name
          );
        }
        if (typeof allImages?.sectionImage == "object") {
          formData.append(
            "section_image",
            allImages?.sectionImage,
            allImages?.sectionImage?.name
          );
        }
        if (typeof allImages?.firstKeyBenefitImage == "object") {
          formData.append(
            "benefit_first_image",
            allImages?.firstKeyBenefitImage,
            allImages?.firstKeyBenefitImage?.name
          );
        }
        if (typeof allImages?.secondKeyBenefitImage == "object") {
          formData.append(
            "benefit_second_image",
            allImages?.secondKeyBenefitImage,
            allImages?.secondKeyBenefitImage?.name
          );
        }

        if (itemData?.id) {
          await FreightsServices.update("CrossFreight", formData);
        } else {
          await FreightsServices.create("CrossFreight", formData);
        }
        setLoader(true);
      }
    } catch (error) {
      ErrorToaster(error);
    }
  };

  const getItemData = async () => {
    let res = await FreightsServices.get("CrossFreight");
    let data = res?.data[0];
    setItemData(data);
    setAllContents({
      mainContent: {
        text: data?.description,
        length: data?.description?.length,
      },
      sectionContent: {
        text: data?.section_description,
        length: data?.section_description?.length,
      },
      firstKeyBenefit: {
        text: data?.benefit_first_description,
        length: data?.benefit_first_description?.length,
      },
      secondKeyBenefit: {
        text: data?.benefit_second_description,
        length: data?.benefit_second_description?.length,
      },
    });
    setAllImages({
      bannerImage: data?.banner_image,
      firstKeyBenefitImage: data?.benefit_first_image,
      secondKeyBenefitImage: data?.benefit_second_image,
      sectionImage: data?.section_image,
    });
    setLoader(false);
  };

  useEffect(() => {
    if (loader) {
      getItemData();
    }
  }, [loader]);
  return (
    <Layout>
      <Box
        sx={{ backgroundColor: colors.lightGray, p: 3, borderRadius: "12px" }}
      >
        <Grid
          container
          alignItems="center"
          justifyContent="space-between"
          sx={{ bgcolor: colors.white, p: 2, borderRadius: "12px" }}
        >
          <Grid item xs={6}>
            <Typography variant="body1" fontWeight="bold" color="initial">
              Cross Border Freight Management
            </Typography>
          </Grid>
        </Grid>

        {loader ? (
          <LoadingCircle />
        ) : (
          <Box sx={{ mt: 2 }}>
            <ImageEditorCombine
              title={"Banner Image"}
              handleData={(e) => setAllImages({ ...allImages, bannerImage: e })}
              uploadedImage={allImages?.bannerImage}
            />
            <TextEditorCombine
              title="Main Content"
              characterLimit={characterLength.subContent}
              data={itemData?.description}
              handleData={(e, contentLength) =>
                setAllContents({
                  ...allContents,
                  mainContent: {
                    text: e,
                    length: contentLength,
                  },
                })
              }
            />
            <TextEditorCombine
              title="Section Content"
              characterLimit={characterLength.mainContent}
              data={itemData?.section_description}
              handleData={(e, contentLength) =>
                setAllContents({
                  ...allContents,
                  sectionContent: {
                    text: e,
                    length: contentLength,
                  },
                })
              }
            />
            <ImageEditorCombine
              title={"Section Image"}
              handleData={(e) =>
                setAllImages({ ...allImages, sectionImage: e })
              }
              uploadedImage={allImages?.sectionImage}
            />
            <TextEditorCombine
              title="Key Benefit First Content"
              characterLimit={characterLength.subContent}
              data={itemData?.benefit_first_description}
              handleData={(e, contentLength) =>
                setAllContents({
                  ...allContents,
                  firstKeyBenefit: {
                    text: e,
                    length: contentLength,
                  },
                })
              }
            />
            <ImageEditorCombine
              title={"Key Benefit First Image"}
              handleData={(e) =>
                setAllImages({ ...allImages, firstKeyBenefitImage: e })
              }
              uploadedImage={allImages?.firstKeyBenefitImage}
            />
            <TextEditorCombine
              title="Key Benefit Second Content"
              characterLimit={characterLength.subContent}
              data={itemData?.benefit_second_description}
              handleData={(e, contentLength) =>
                setAllContents({
                  ...allContents,
                  secondKeyBenefit: {
                    text: e,
                    length: contentLength,
                  },
                })
              }
            />
            <ImageEditorCombine
              title={"Key Benefit Second Image"}
              handleData={(e) =>
                setAllImages({ ...allImages, secondKeyBenefitImage: e })
              }
              uploadedImage={allImages?.secondKeyBenefitImage}
            />
            <PrimaryButton
              title="Save"
              onClick={() => handleDataSubmission()}
              style={{
                margin: "10px 0px",
              }}
            />
          </Box>
        )}
      </Box>
    </Layout>
  );
}

export default CrossBorderFreight;
