import React from "react";
import {
  FormControl,
  InputLabel,
  OutlinedInput,
  TextField,
} from "@mui/material";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/material.css";
import { makeStyles } from "@mui/styles";

import { Error } from "./Error";

const useStyles = makeStyles({
  borderClass: {
    "&.react-tel-input .form-control": {
      borderColor: "#D32F2F",
      border: "1px solid #D32F2F",
    },
  },
});

export function InputField({
  label,
  error,
  register,
  type,
  readOnly,
  defaultValue,
  disable,
}) {
  return (
    <FormControl variant="standard" fullWidth sx={{ my: 1 }}>
      <TextField
        disabled={disable}
        inputProps={{ readOnly: readOnly ? true : false }}
        sx={{
          mb: error ? "0px" : 2,
        }}
        size={"small"}
        variant="outlined"
        label={label}
        defaultValue={defaultValue}
        type={type}
        error={error && true}
        {...register}
      />
      {error && <Error message={error} />}
    </FormControl>
  );
}

export function OutlineInputField({
  label,
  error,
  register,
  endAdornment,
  updatedValue,
  type,
  size,
  onClick,
  disable,
}) {
  return (
    <FormControl size={"small"} fullWidth>
      <InputLabel error={error && true}>{label}</InputLabel>
      <OutlinedInput
        disabled={disable}
        onClick={onClick}
        endAdornment={endAdornment}
        label={label}
        type={type}
        sx={{
          mb: error ? "0px" : 2,
        }}
        value={updatedValue}
        variant="outlined"
        error={error && true}
        {...register}
      />
      {error && <Error message={error} />}
    </FormControl>
  );
}

export function PhoneInputField({
  value,
  onChange,
  label,
  error,
  register,
  disable,
  endAdornment,
  updatedValue,
  type,
  size,
  onClick,
}) {
  const classes = useStyles();
  return (
    <FormControl
      size="small"
      fullWidth
      variant="outlined"
      sx={{ mb: error ? 0 : 2 }}
    >
      <PhoneInput
        disabled={disable}
        country="pk"
        inputStyle={{
          width: "100%",
        }}
        containerClass={error ? classes.borderClass : ""}
        value={value}
        placeholder="+92 333-3333333"
        error={error && true}
        {...register}
        onChange={onChange}
      />
      {error && <Error message={error} />}
    </FormControl>
  );
}
