import React, { useEffect, useState } from "react";
import {
  Box,
  CircularProgress,
  Grid,
  IconButton,
  Pagination,
  Tooltip,
  Typography,
} from "@mui/material";
import Layout from "app/layout/Layout";
import colors from "app/styles/colors";
import BreadCrumbs from "app/components/Breadcrumbs/Breadcrumbs";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import BookingsServices from "app/apis/Bookings/BookingsServices";
import { Check, Close, ReceiptTwoTone } from "@mui/icons-material";
import { SuccessToaster } from "app/components/Toaster/Toaster";
import UploadInvoicePOD from "app/components/Dialog/UploadInvoicePOD";

function LandFreightBookings() {
  const [dataList, setDataList] = useState([]);
  const [currentLinks, setCurrentLinks] = useState([]);
  const [listLoader, setListLoader] = useState(true);
  const [selectedData, setSelectedData] = useState({ type: "", id: "" });
  const [showDialog, setShowDialog] = useState(false);

  const getData = async (pageNo) => {
    try {
      setListLoader(true);
      let params = { page: pageNo ?? 1 };
      let res = await BookingsServices.getBookLandFreight(params);
      setCurrentLinks(res?.data?.meta?.links?.slice(1, -1));
      setDataList(res?.data?.data);
    } catch (error) {
      console.log("🚀 ~ getData ~ error:", error);
    } finally {
      setListLoader(false);
    }
  };

  const updateStatus = async (id, status) => {
    try {
      let res = await BookingsServices.updateLandStatus({
        id,
        status,
      });
      SuccessToaster(res?.message);
      getData();
    } catch (error) {
      console.log("🚀 ~ updateStatus ~ error:", error);
    }
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <Layout>
      <UploadInvoicePOD
        open={showDialog}
        data={selectedData}
        handleClose={() => setShowDialog(false)}
        moduleName="BookLand"
      />
      <Box
        sx={{ backgroundColor: colors.lightGray, p: 3, borderRadius: "12px" }}
      >
        <Grid
          container
          alignItems="center"
          justifyContent="space-between"
          sx={{ bgcolor: colors.white, p: 2, borderRadius: "12px" }}
        >
          <Grid item xs={6}>
            <Typography variant="body1" fontWeight="bold" color="initial">
              Land Freight Bookings
            </Typography>
          </Grid>
          <Grid
            item
            xs={6}
            sx={{ display: "flex", justifyContent: "flex-end" }}
          >
            <BreadCrumbs currentPage="Land Freight Bookings" />
          </Grid>
        </Grid>

        <Box sx={{ mt: 2, bgcolor: colors.white, p: 2, borderRadius: 4 }}>
          {listLoader ? (
            <Box sx={{ textAlign: "center" }}>
              <CircularProgress color="success" />
            </Box>
          ) : (
            <>
              <TableContainer component={Paper} sx={{ my: 2 }}>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                  <TableHead>
                    <TableRow sx={{ bgcolor: colors.primary }}>
                      <TableCell sx={{ color: "white", fontWeight: "bold" }}>
                        Name
                      </TableCell>
                      <TableCell sx={{ color: "white", fontWeight: "bold" }}>
                        Email
                      </TableCell>
                      <TableCell sx={{ color: "white", fontWeight: "bold" }}>
                        Contact
                      </TableCell>
                      <TableCell sx={{ color: "white", fontWeight: "bold" }}>
                        Cargo Type
                      </TableCell>
                      <TableCell sx={{ color: "white", fontWeight: "bold" }}>
                        Loading Address
                      </TableCell>
                      <TableCell sx={{ color: "white", fontWeight: "bold" }}>
                        Off-Loading Address
                      </TableCell>
                      <TableCell sx={{ color: "white", fontWeight: "bold" }}>
                        Loading Date
                      </TableCell>
                      <TableCell sx={{ color: "white", fontWeight: "bold" }}>
                        Off-Loading Date
                      </TableCell>
                      <TableCell sx={{ color: "white", fontWeight: "bold" }}>
                        Status
                      </TableCell>
                      <TableCell sx={{ color: "white", fontWeight: "bold" }}>
                        Action
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {dataList?.map((row, index) => (
                      <TableRow
                        sx={{
                          bgcolor: index % 2 ? colors.lightGray : "white",
                        }}
                        key={row.user_name}
                      >
                        <TableCell>{row?.user_name ?? "-"}</TableCell>
                        <TableCell>{row?.user_email ?? "-"}</TableCell>
                        <TableCell>{row?.user_contact_no ?? "-"}</TableCell>
                        <TableCell>{row?.cargo_type ?? "-"}</TableCell>
                        <TableCell>
                          {row?.cl_from_address + " - " + row?.cl_from_city ??
                            "-"}
                        </TableCell>
                        <TableCell>
                          {row?.col_at_address + " - " + row?.col_at_city ??
                            "-"}
                        </TableCell>
                        <TableCell>{row?.cl_date ?? "-"}</TableCell>
                        <TableCell>{row?.col_date ?? "-"}</TableCell>
                        <TableCell>{row?.status}</TableCell>
                        <TableCell>
                          {row?.status == "Pending" ? (
                            <Box sx={{ display: "flex" }}>
                              <IconButton
                                onClick={() =>
                                  updateStatus(row?.id, "Accepted")
                                }
                                size="small"
                              >
                                <Check color="success" />
                              </IconButton>
                              <IconButton
                                onClick={() =>
                                  updateStatus(row?.id, "Rejected")
                                }
                                size="small"
                              >
                                <Close color="error" />
                              </IconButton>
                            </Box>
                          ) : row?.status == "Accepted" ? (
                            <>
                              <Box sx={{ display: "flex" }}>
                                <IconButton
                                  onClick={() => {
                                    setSelectedData({
                                      id: row?.id,
                                      type: "invoice",
                                    });
                                    setShowDialog(true);
                                  }}
                                  size="small"
                                >
                                  <Tooltip title="Upload Invoice">
                                    <ReceiptTwoTone color="warning" />
                                  </Tooltip>
                                </IconButton>
                                <IconButton
                                  onClick={() => {
                                    setSelectedData({
                                      id: row?.id,
                                      type: "pod",
                                    });
                                    setShowDialog(true);
                                  }}
                                  size="small"
                                >
                                  <Tooltip title="Upload POD">
                                    <ReceiptTwoTone color="info" />
                                  </Tooltip>
                                </IconButton>
                              </Box>
                            </>
                          ) : null}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </>
          )}
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            <Pagination
              variant="outlined"
              color="success"
              count={currentLinks?.length}
              onChange={(e, value) => getData(value)}
            />
          </Box>
        </Box>
      </Box>
    </Layout>
  );
}

export default LandFreightBookings;
