import { get, post, patch, deleted } from "app/apis";
import AboutRoutes from "./AboutRoutes";

const AboutServices = {
  getAboutBanner: async (routeName) => {
    const data = await get(`/get${routeName}`);
    return data;
  },
  createAboutBanner: async (routeName, obj) => {
    const data = await post(`/create${routeName}`, obj);
    return data;
  },
  updateAbouBanner: async (routeName, obj) => {
    const data = await post(`/update${routeName}`, obj);
    return data;
  },

  getAboutPtn: async () => {
    const data = await get(AboutRoutes.getAboutPtn);
    return data;
  },
  createAboutPtn: async (obj) => {
    const data = await post(AboutRoutes.createAboutPtn, obj);
    return data;
  },
  updateAboutPtn: async (obj) => {
    const data = await post(AboutRoutes.updateAboutPtn, obj);
    return data;
  },

  createGeneralContent: async (obj) => {
    const data = await post(AboutRoutes.createGeneralContent, obj);
    return data;
  },
  updateGeneralContent: async (obj) => {
    const data = await post(AboutRoutes.updateGeneralContent, obj);
    return data;
  },
  getGeneralContent: async (obj) => {
    const data = await get(AboutRoutes.getGeneralContent, obj);
    return data;
  },
};

export default AboutServices;
