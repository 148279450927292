import { get, post, patch, deleted } from "app/apis";
import OurHistoryRoutes from "./OurHistoryRoutes";

const OurHistoryServices = {
  getOurVision: async () => {
    const data = await get(OurHistoryRoutes.getHistoryVision);
    return data;
  },
  createOurVision: async (obj) => {
    const data = await post(OurHistoryRoutes.createHistoryVision, obj);
    return data;
  },
  updateOurVision: async (obj) => {
    const data = await post(OurHistoryRoutes.updateHistoryVision, obj);
    return data;
  },

  getHistory: async () => {
    const data = await get(OurHistoryRoutes.getHistory);
    return data;
  },
  createHistory: async (obj) => {
    const data = await post(OurHistoryRoutes.createHistory, obj);
    return data;
  },
  updateHistory: async (obj) => {
    const data = await post(OurHistoryRoutes.updateHistory, obj);
    return data;
  },
  deleteHistory: async (obj) => {
    const data = await post(OurHistoryRoutes.deleteHistory, obj);
    return data;
  },
};

export default OurHistoryServices;
