import { get, post, patch, deleted } from "app/apis";
import DashboardRoutes from "./DashboardRoutes";

const DashboardServices = {
  getCardsCount: async () => {
    const data = await get(DashboardRoutes.getCardsCount);
    return data;
  },
};

export default DashboardServices;
