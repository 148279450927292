import React, { useRef, useState } from "react";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  IconButton,
  Typography,
} from "@mui/material";
import { CloseRounded } from "@mui/icons-material";
import { PrimaryButton } from "../Buttons/Button";
import { useForm } from "react-hook-form";
import { Editor } from "@tinymce/tinymce-react";
import { InputField } from "../UI/TextField";
import CareerServices from "app/apis/Career/CareerServices";

function AddJob({ handleClose, open, reload, characterLimit, selectedData }) {
  const {
    handleSubmit,
    formState: { errors },
    reset,
    register,
  } = useForm();

  const [contentLength, setContentLength] = useState(0);
  const editorRef = useRef(null);

  const formReset = () => {
    reset();
  };

  const addListITem = async (data, desp) => {
    let formData = new FormData();
    formData.append("title", data.title);
    formData.append("subject", data.subject);
    formData.append("description", desp);
    // {
    //   selectedData?.id && formData.append("id", selectedData?.id);
    // }
    try {
      await CareerServices.createCareerJob(formData);
      formReset();
      handleClose();
      reload();
    } catch (error) {
      console.log("🚀 ~ addListITem ~ error:", error);
    }
  };

  const handleEditorData = (formData) => {
    if (editorRef.current) {
      let contentLengthCheck = editorRef.current.getContent({
        format: "text",
      }).length;
      if (contentLengthCheck > 0 && contentLengthCheck <= characterLimit) {
        addListITem(formData, editorRef.current.getContent());
      } else {
        alert(`Character limit exceed from ${characterLimit}.`);
      }
    }
  };

  const handleEditorChange = (e, editor) => {
    const newContent = editor.getContent({ format: "text" });
    setContentLength(newContent?.length);
  };

  return (
    <Dialog
      open={open}
      fullWidth
      onClose={() => {
        handleClose();
      }}
      sx={{ "& .MuiDialog-paper": { borderRadius: "12px" } }}
    >
      <DialogTitle
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        {"Create Job"}
        <IconButton
          onClick={() => {
            handleClose();
          }}
        >
          <CloseRounded />
        </IconButton>
      </DialogTitle>
      <Divider />
      <DialogContent>
        <Typography
          component={"form"}
          onSubmit={handleSubmit(handleEditorData)}
        >
          <Grid container alignItems="center">
            <Grid item xs={12}>
              <InputField
                label="Title"
                error={errors?.title?.message}
                register={register("title", {
                  required: "Please enter title.",
                })}
              />
            </Grid>
            <Grid item xs={12}>
              <InputField
                label="Subject"
                error={errors?.title?.message}
                register={register("subject", {
                  required: "Please enter subject.",
                })}
              />
            </Grid>
            <Grid item xs={12}>
              <Editor
                apiKey="x0zopq140mjc2x8ptqr6uybhoj5zq8owbik6g8ad28al4md8"
                onInit={(evt, editor) => (editorRef.current = editor)}
                initialValue={selectedData?.description}
                init={{
                  selector: "textarea",
                  branding: false,
                  height: 300,
                  menubar: false,
                  plugins: ["lists "],
                  toolbar: "blocks | bold italic underline | numlist bullist ",
                  content_style:
                    "body { font-family:sans-serif; font-size:14px }",
                }}
                onEditorChange={handleEditorChange}
              />
              <Typography>
                {contentLength}/{characterLimit}
              </Typography>
            </Grid>
          </Grid>

          <PrimaryButton
            type="submit"
            myStyle={{ mt: 1, borderRadius: "12px" }}
            fullWidth={true}
            title="Save"
          />
        </Typography>
      </DialogContent>
    </Dialog>
  );
}

export default AddJob;
