import { get, post, patch, deleted } from "app/apis";
import WarehouseRoutes from "./WarehouseRoutes";

const WarehouseServices = {
  createWarehouse: async (obj) => {
    const data = await post(WarehouseRoutes.createWarehouse, obj);
    return data;
  },
  getWarehouse: async () => {
    const data = await get(WarehouseRoutes.getWarehouse);
    return data;
  },
  updateWarehouse: async (obj) => {
    const data = await post(WarehouseRoutes.updateWarehouse, obj);
    return data;
  },

  createPtnCard: async (obj) => {
    const data = await post(WarehouseRoutes.createPtnCard, obj);
    return data;
  },
  getPtnCard: async () => {
    const data = await get(WarehouseRoutes.getPtnCard);
    return data;
  },
  updatePtnCard: async (obj) => {
    const data = await post(WarehouseRoutes.updatePtnCard, obj);
    return data;
  },
  deletePtnCard: async (obj) => {
    const data = await post(WarehouseRoutes.deletePtnCard, obj);
    return data;
  },
};

export default WarehouseServices;
