import React, { Fragment, useState } from "react";
import {
  Grid,
  Box,
  MenuItem,
  Menu,
  Avatar,
  Typography,
  Divider,
  Button,
  AppBar,
  Toolbar,
} from "@mui/material";
import {
  NotificationsNone,
  SettingsOutlined,
  Person,
  PersonAdd,
} from "@mui/icons-material";

import useAuth from "app/hooks/useAuth";
import colors from "app/styles/colors";
import Images from "app/assets/Images";

//  *Imported Component
import { PrimaryButton } from "app/components/Buttons/Button";

function Header() {
  const { logout, name, email } = useAuth();

  // *Anchor Box
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    if (anchorEl) {
      setAnchorEl(null);
    } else {
      setAnchorEl(event.currentTarget);
    }
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Fragment>
      <AppBar
        position="fixed"
        open={true}
        sx={{ mb: 20, bgcolor: colors.white, boxShadow: 0 }}
      >
        <Toolbar></Toolbar>
      </AppBar>
    </Fragment>
  );
}

export default Header;
