const BookingsRoute = {
  getRentWarehouseSpace: "/getRentWarehouseSpace",
  getBookLandFreight: "/getBookLandFreight",
  getBookSeaFreight: "/getBookSeaFreight",
  getMoveYourCar: "/getMoveYourCar",
  updateLandStatus: "/updateBookLandStatus",
  updateSeaStatus: "/updateBookSeaStatus",
  updateMoveStatus: "/updateMoveStatus",
  updateRentStatus: "/updateRentStatus",
  uploadInvPodFor: "/uploadInvPodFor",
};

export default BookingsRoute;
