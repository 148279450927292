import React, { useEffect, useState } from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Grid,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import Layout from "app/layout/Layout";
import colors from "app/styles/colors";
import BreadCrumbs from "app/components/Breadcrumbs/Breadcrumbs";
import TextEditor from "../../components/Editors/TextEditor";
import ImageEditor from "../../components/Editors/ImageEditor";
import { EditTwoTone, ExpandMore } from "@mui/icons-material";
import AddLogisticsCard from "app/components/Dialog/AddLogisticsCard";
import { LoadingCircle } from "app/components/UI/Loader";
import GeneralServices from "app/apis/General/GeneralServices";
import { ErrorToaster, SuccessToaster } from "app/components/Toaster/Toaster";
import OurLogisticsServices from "app/apis/Logistics/OurLogisticsServices";

function createData(year, title) {
  return { year, title };
}

const rows = [createData(2010, "Our Beginning")];

function LogisticsSolution() {
  const [showDialog, setShowDialog] = useState(false);
  const [loader, setLoader] = useState(true);
  const [mainBanner, setMainBanner] = useState("");
  const [subImage, setSubImage] = useState("");
  const [ourLogistics, setOurLogistics] = useState({});
  const [subContent, setSubContent] = useState({});
  const [listData, setListData] = useState([]);
  const [selectedItem, setSelectedItem] = useState("");

  const submitBanner = async (route, data, oldData) => {
    try {
      let formData = new FormData();
      {
        oldData?.id && formData.append("id", oldData?.id);
      }
      formData.append("image", data, data?.name);
      let result = "";
      if (oldData) {
        result = await GeneralServices.updateBanner(route, formData);
      } else {
        result = await GeneralServices.createBanner(route, formData);
      }
      SuccessToaster(result?.message);
      setLoader(true);
    } catch (error) {
      ErrorToaster(error);
      console.log("🚀 ~ submitBanner ~ error:", error);
    }
  };

  const handleOurLogistics = async (data) => {
    try {
      let formData = new FormData();
      {
        ourLogistics?.id && formData.append("id", ourLogistics?.id);
      }
      formData.append("description", data.description);
      let result = "";
      if (ourLogistics?.id) {
        result = await OurLogisticsServices.updateMainText(formData);
      } else {
        result = await OurLogisticsServices.createMainText(formData);
      }
      SuccessToaster(result?.message);
      setLoader(true);
    } catch (error) {
      console.log("🚀 ~ handleOurLogistics ~ error:", error);
    }
  };

  const handleSubContent = async (data) => {
    try {
      let formData = new FormData();
      {
        subContent?.id && formData.append("id", subContent?.id);
      }
      formData.append("description", data.description);
      let result = "";
      if (subContent?.id) {
        result = await OurLogisticsServices.updateSubText(formData);
      } else {
        result = await OurLogisticsServices.createSubText(formData);
      }
      SuccessToaster(result?.message);
      setLoader(true);
    } catch (error) {
      console.log("🚀 ~ handleSubContent ~ error:", error);
    }
  };

  // get apis ===========
  const getAllImages = async () => {
    try {
      let result = await GeneralServices.getBanner("LogisticsBanner");
      let result1 = await GeneralServices.getBanner("LogisticFirstImg");
      setMainBanner(result?.data[0]);
      setSubImage(result1?.data[0]);
    } catch (error) {
      console.log("🚀 ~ getAllImages ~ error:", error);
    }
  };

  const getTextContent = async () => {
    try {
      let result = await OurLogisticsServices.getMainText();
      let result1 = await OurLogisticsServices.getSubText();
      let result3 = await OurLogisticsServices.getLogCard();
      setOurLogistics(result?.data[0]);
      setSubContent(result1?.data[0]);
      setListData(result3?.data);
    } catch (error) {
      console.log("🚀 ~ getOurVision ~ error:", error);
    }
  };

  const getAllData = async () => {
    try {
      await getAllImages();
      await getTextContent();
    } catch (error) {
      console.log("🚀 ~ getAllData ~ error:", error);
    } finally {
      setLoader(false);
    }
  };

  useEffect(() => {
    if (loader) {
      getAllData();
    }
  }, [loader]);

  return (
    <Layout>
      <AddLogisticsCard
        handleClose={() => setShowDialog(false)}
        open={showDialog}
        characterLimit={150}
        selectedData={selectedItem}
        reload={() => setLoader(true)}
      />
      <Box
        sx={{ backgroundColor: colors.lightGray, p: 3, borderRadius: "12px" }}
      >
        <Grid
          container
          alignItems="center"
          justifyContent="space-between"
          sx={{ bgcolor: colors.white, p: 2, borderRadius: "12px" }}
        >
          <Grid item xs={6}>
            <Typography variant="body1" fontWeight="bold" color="initial">
              Logistics Solution Management
            </Typography>
          </Grid>
          <Grid
            item
            xs={6}
            sx={{ display: "flex", justifyContent: "flex-end" }}
          >
            <BreadCrumbs currentPage="Logistics Solution Management" />
          </Grid>
        </Grid>
        {loader ? (
          <LoadingCircle />
        ) : (
          <Box sx={{ mt: 2 }}>
            <ImageEditor
              title={"Banner Image"}
              handleData={(e) => submitBanner("LogisticsBanner", e, mainBanner)}
              uploadedImage={mainBanner}
            />
            <TextEditor
              title="Main Text"
              characterLimit={490}
              data={ourLogistics}
              handleData={(e) => handleOurLogistics(e)}
            />
            <ImageEditor
              title={"First Image"}
              handleData={(e) => submitBanner("LogisticFirstImg", e, subImage)}
              uploadedImage={subImage}
            />
            <TextEditor
              title="Sub Text"
              characterLimit={185}
              data={subContent}
              handleData={(e) => handleSubContent(e)}
            />
            {/* ============Table============ */}
            <Accordion
              sx={{
                mb: 1,
                boxShadow: "none",
                "&::before": {
                  display: "none",
                },
              }}
            >
              <AccordionSummary expandIcon={<ExpandMore />}>
                <Typography variant="body1" fontWeight="bold" color="initial">
                  Three Logistic Cards
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <TableContainer component={Paper}>
                  <Table sx={{ minWidth: 650 }} aria-label="simple table">
                    <TableHead>
                      <TableRow sx={{ bgcolor: colors.primary }}>
                        <TableCell
                          sx={{ fontWeight: "bold", color: colors.white }}
                        >
                          Image
                        </TableCell>
                        <TableCell
                          sx={{ fontWeight: "bold", color: colors.white }}
                        >
                          Title
                        </TableCell>
                        <TableCell
                          sx={{ fontWeight: "bold", color: colors.white }}
                        >
                          Action
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {listData?.map((row, index) => (
                        <TableRow
                          key={row?.name}
                          sx={{
                            "&:last-child td, &:last-child th": { border: 0 },
                          }}
                        >
                          <TableCell component="th" scope="row">
                            <Typography
                              component={"img"}
                              sx={{
                                width: 70,
                                height: 70,
                                objectFit: "contain",
                                borderRadius: 70,
                              }}
                              src={row?.image}
                            />
                          </TableCell>
                          <TableCell component="th" scope="row">
                            {index == 0
                              ? "Transportation Service"
                              : index == 1
                              ? "Logistics & Supply Chain Services"
                              : "Digital Logistics"}
                          </TableCell>
                          <TableCell>
                            <IconButton
                              onClick={() => {
                                setSelectedItem(row);
                                setShowDialog(true);
                              }}
                            >
                              <EditTwoTone color="primary" />
                            </IconButton>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </AccordionDetails>
            </Accordion>
          </Box>
        )}
      </Box>
    </Layout>
  );
}

export default LogisticsSolution;
