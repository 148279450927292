const FleetManagementRoutes = {
  createLastMile: "/createLastMile",
  getLastMile: "/getLastMile",
  updateLastMile: "/updateLastMile",

  createFleetCard: "/createFleetCard",
  getFleetCard: "/getFleetCard",
  updateFleetCard: "/updateFleetCard",
  deleteFleetCard: "/deleteFleetCard",
};

export default FleetManagementRoutes;
