import { get, post, patch, deleted } from "app/apis";
import FleetManagementRoutes from "./FleetManagementRoutes";

const FleetManagementServices = {
  createLastMile: async (obj) => {
    const data = await post(FleetManagementRoutes.createLastMile, obj);
    return data;
  },
  getLastMile: async () => {
    const data = await get(FleetManagementRoutes.getLastMile);
    return data;
  },
  updateLastMile: async (obj) => {
    const data = await post(FleetManagementRoutes.updateLastMile, obj);
    return data;
  },

  createFleetCard: async (obj) => {
    const data = await post(FleetManagementRoutes.createFleetCard, obj);
    return data;
  },
  getFleetCard: async () => {
    const data = await get(FleetManagementRoutes.getFleetCard);
    return data;
  },
  updateFleetCard: async (obj) => {
    const data = await post(FleetManagementRoutes.updateFleetCard, obj);
    return data;
  },
  deleteFleetCard: async (obj) => {
    const data = await post(FleetManagementRoutes.deleteFleetCard, obj);
    return data;
  },
};

export default FleetManagementServices;
