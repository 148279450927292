import React, { useEffect, useRef, useState } from "react";
import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  Radio,
  RadioGroup,
  Typography,
} from "@mui/material";
import { CloseRounded } from "@mui/icons-material";
import useAuth from "app/hooks/useAuth";
import { ErrorToaster, SuccessToaster } from "../Toaster/Toaster";
import { InputField, PhoneInputField } from "../UI/TextField";
import { emailRegex } from "app/utils";
import SelectField from "../UI/SelectFields";
import { PrimaryButton } from "../Buttons/Button";
import { Error } from "../UI/Error";
import { useForm } from "react-hook-form";
import colors from "app/styles/colors";
import Images from "app/assets/Images";
import { Editor } from "@tinymce/tinymce-react";

function WhyPTNDialog({
  handleClose,
  open,
  toggleFunction,
  characterLimit,
  handleSubmitFunc,
  selectedItem,
}) {
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    reset,
  } = useForm();

  const [imageFile, setImageFile] = useState("");
  const [contentLength, setContentLength] = useState(0);
  const editorRef = useRef(null);

  const handleData = async (data, desp) => {
    if (imageFile) {
      let formData = new FormData();
      {
        selectedItem?.id && formData.append("id", selectedItem?.id);
      }
      formData.append("title", data.title);
      formData.append("description", desp);
      if (typeof imageFile == "object") {
        formData.append("image", imageFile, imageFile?.name);
      }
      handleSubmitFunc(formData, selectedItem?.id);
      formReset();
      handleClose();
    } else {
      ErrorToaster("Image is required.");
    }
  };

  const uploadImage = async (e) => {
    try {
      if (e.target.files) {
        setImageFile(e.target.files[0]);
      }
    } catch (error) {
      console.log(
        "🚀 ~ file: AddNewLeader.js:57 ~ uploadImage ~ error:",
        error
      );
    }
  };

  const formReset = () => {
    setImageFile("");
    reset();
  };

  const handleEditorData = (formData) => {
    if (editorRef.current) {
      let contentLengthCheck = editorRef.current.getContent({
        format: "text",
      }).length;
      if (contentLengthCheck > 0 && contentLengthCheck <= characterLimit) {
        handleData(formData, editorRef.current.getContent());
      } else {
        alert(`Character limit exceed from ${characterLimit}.`);
      }
    }
  };

  const handleEditorChange = (e, editor) => {
    const newContent = editor.getContent({ format: "text" });
    setContentLength(newContent?.length);
  };

  useEffect(() => {
    if (selectedItem) {
      setValue("title", selectedItem?.title);
      setImageFile(selectedItem?.image);
    }
  }, [selectedItem]);

  return (
    <Dialog
      open={open}
      fullWidth
      onClose={() => {
        formReset();
        handleClose();
      }}
      sx={{ "& .MuiDialog-paper": { borderRadius: "12px" } }}
    >
      <DialogTitle
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        {selectedItem?.id ? "Edit Item" : "Add New Item"}
        <IconButton
          onClick={() => {
            handleClose();
            formReset();
          }}
        >
          <CloseRounded />
        </IconButton>
      </DialogTitle>
      <Divider />
      <DialogContent>
        <Typography
          component={"form"}
          onSubmit={handleSubmit(handleEditorData)}
        >
          <Grid container alignItems="center">
            <Grid item xs={12}>
              <InputField
                label="Title"
                error={errors?.title?.message}
                register={register("title", {
                  required: "Please enter title.",
                })}
              />
            </Grid>

            <Grid item xs={12}>
              <Editor
                apiKey="x0zopq140mjc2x8ptqr6uybhoj5zq8owbik6g8ad28al4md8"
                onInit={(evt, editor) => (editorRef.current = editor)}
                initialValue={selectedItem?.description}
                init={{
                  selector: "textarea",
                  branding: false,
                  height: 300,
                  menubar: false,
                  plugins: ["lists "],
                  toolbar: "blocks | bold italic underline | numlist bullist ",
                  content_style:
                    "body { font-family:sans-serif; font-size:14px }",
                }}
                onEditorChange={handleEditorChange}
              />
              <Typography>
                {contentLength}/{characterLimit}
              </Typography>
            </Grid>

            <Grid item xs={12}>
              <FormControl fullWidth sx={{ my: 0.5 }}>
                <Box
                  sx={{
                    border: `1px dashed ${colors.lightGray}`,
                    textAlign: "center",
                    height: 300,
                    position: "relative",
                    overflow: "hidden",
                    p: 1,
                  }}
                >
                  <label htmlFor={"leaderImage"}>
                    <img
                      src={
                        typeof imageFile == "string"
                          ? imageFile
                          : imageFile
                          ? URL.createObjectURL(imageFile)
                          : Images.nicPlaceholder
                      }
                      style={{
                        objectFit: "contain",
                        width: "100%",
                        height: 300,
                      }}
                    />
                  </label>
                  <input
                    style={{ display: "none" }}
                    id={"leaderImage"}
                    type="file"
                    onChange={(e) => uploadImage(e)}
                    accept="image/*"
                  />
                </Box>
              </FormControl>
            </Grid>
          </Grid>

          <PrimaryButton
            type="submit"
            // loading={loading}
            myStyle={{ mt: 1, borderRadius: "12px" }}
            fullWidth={true}
            title="Save"
          />
        </Typography>
      </DialogContent>
    </Dialog>
  );
}

export default WhyPTNDialog;
