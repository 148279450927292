import { AttachFileOutlined, ExpandMore } from "@mui/icons-material";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  FormControl,
  Typography,
} from "@mui/material";
import Images from "app/assets/Images";
import { PrimaryButton } from "app/components/Buttons/Button";
import colors from "app/styles/colors";
import React, { useState } from "react";

function ImageEditor({ title, handleData, uploadedImage }) {
  const [imageFile, setImageFile] = useState(uploadedImage);
  const uploadImage = async (e) => {
    try {
      if (e.target.files) {
        setImageFile(e.target.files[0]);
      }
    } catch (error) {
      console.log(
        "🚀 ~ file: AddEquipment.js ~ line 345 ~ uploadImage ~ error",
        error
      );
    }
  };

  const handleDataSubmission = () => {
    if (imageFile?.name) {
      handleData(imageFile);
    } else {
      alert("Please upload image to proceed.");
    }
  };
  return (
    <Accordion
      sx={{
        mb: 1,
        boxShadow: "none",
        "&::before": {
          display: "none",
        },
      }}
    >
      <AccordionSummary expandIcon={<ExpandMore />}>
        <Typography variant="body1" fontWeight="bold" color="initial">
          {title}
        </Typography>
      </AccordionSummary>
      <AccordionDetails>
        <FormControl fullWidth sx={{ my: 0.5 }}>
          <Box
            sx={{
              border: `1px dashed ${colors.lightGray}`,
              textAlign: "center",
              height: 300,
              position: "relative",
              overflow: "hidden",
              p: 1,
            }}
          >
            <label htmlFor={title}>
              <img
                src={
                  imageFile?.image
                    ? imageFile?.image
                    : imageFile
                    ? URL.createObjectURL(imageFile)
                    : Images.iconPlaceholder
                }
                style={{
                  objectFit: "contain",
                  width: "100%",
                  height: 300,
                }}
              />
            </label>
            <input
              style={{ display: "none" }}
              id={title}
              type="file"
              onChange={(e) => uploadImage(e)}
              accept="image/*"
            />
          </Box>
        </FormControl>
        <PrimaryButton
          title="Save"
          onClick={() => handleDataSubmission()}
          style={{
            margin: "10px 0px",
          }}
        />
      </AccordionDetails>
    </Accordion>
  );
}

export default ImageEditor;
