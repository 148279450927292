import { get, post, patch, deleted } from "app/apis";
import OurVisionMissionRoutes from "./OurVisionMissionRoutes";

const OurVisionMissionServices = {
  getOurVision: async () => {
    const data = await get(OurVisionMissionRoutes.getOurVision);
    return data;
  },
  createOurVision: async (obj) => {
    const data = await post(OurVisionMissionRoutes.createOurVision, obj);
    return data;
  },
  updateOurVision: async (obj) => {
    const data = await post(OurVisionMissionRoutes.updateOurVision, obj);
    return data;
  },

  getOurMission: async () => {
    const data = await get(OurVisionMissionRoutes.getOurMission);
    return data;
  },
  createOurMission: async (obj) => {
    const data = await post(OurVisionMissionRoutes.createOurMission, obj);
    return data;
  },
  updateOurMission: async (obj) => {
    const data = await post(OurVisionMissionRoutes.updateOurMission, obj);
    return data;
  },
};

export default OurVisionMissionServices;
