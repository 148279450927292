const OurVisionMissionRoutes = {
  createOurVision: "/createOurVision",
  getOurVision: "/getOurVision",
  updateOurVision: "/updateOurVision",

  createOurMission: "/createOurMission",
  getOurMission: "/getOurMission",
  updateOurMission: "/updateOurMission",

  createHistoryVision: "/createHistoryVision",
  getHistoryVision: "/getHistoryVision",
  updateHistoryVision: "/updateHistoryVision",
};

export default OurVisionMissionRoutes;
