import React from "react";
import { Box, Grid, Typography } from "@mui/material";
import Layout from "app/layout/Layout";
import colors from "app/styles/colors";
import BreadCrumbs from "app/components/Breadcrumbs/Breadcrumbs";
import { Link } from "react-router-dom";
import { freightsBookingsNavigation } from "app/Navigation";

function Bookings() {
  return (
    <Layout>
      <Box
        sx={{ backgroundColor: colors.lightGray, p: 3, borderRadius: "12px" }}
      >
        <Grid
          container
          alignItems="center"
          justifyContent="space-between"
          sx={{ bgcolor: colors.white, p: 2, borderRadius: "12px" }}
        >
          <Grid item xs={6}>
            <Typography variant="body1" fontWeight="bold" color="initial">
              All Bookings
            </Typography>
          </Grid>
          <Grid
            item
            xs={6}
            sx={{ display: "flex", justifyContent: "flex-end" }}
          >
            <BreadCrumbs currentPage="All Bookings" />
          </Grid>
        </Grid>

        <Box sx={{ mt: 2, bgcolor: colors.white, p: 2, borderRadius: 4 }}>
          <Grid container spacing={2}>
            {freightsBookingsNavigation.map((item) => (
              <Grid item xs={2}>
                <Link to={item.link} className="linkStyle">
                  <Box
                    sx={{
                      height: 50,
                      borderRadius: 4,
                      p: 2,
                      textAlign: "center",
                      boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
                      bgcolor: "rgba(0, 200, 83,.7)",
                      alignContent: "center",
                    }}
                  >
                    <Typography
                      sx={{
                        color: colors.white,
                        fontSize: 14,
                        textAlign: "center",
                        fontWeight: "bold",
                      }}
                    >
                      {item.title}
                    </Typography>
                  </Box>
                </Link>
              </Grid>
            ))}
          </Grid>
        </Box>
      </Box>
    </Layout>
  );
}

export default Bookings;
