const WarehouseRoutes = {
  createWarehouse: "/createWarehouse",
  getWarehouse: "/getWarehouse",
  updateWarehouse: "/updateWarehouse",

  createPtnCard: "/createPtnCard",
  getPtnCard: "/getPtnCard",
  updatePtnCard: "/updatePtnCard",
  deletePtnCard: "/deletePtnCard",
};

export default WarehouseRoutes;
