const insightsRoutes = {
  createInsightItem: "/createInsightItem",
  getInsightItem: "/getInsightItem",
  updateInsightItem: "/updateInsightItem",
  deleteInsightItem: "/deleteInsightItem",

  getInsightItemInfo: "/getInsightItemInfoById",
  createInsightItemInfo: "/createInsightItemInfo",
  updateInsightItemInfo: "/updateInsightItemInfo",
};

export default insightsRoutes;
