import moment from "moment";

// *Current Year
export const CurrentYear = () => {
  let date = new Date();
  const year = date.getFullYear();
  return year;
};

// *Current Date
export const CurrentDate = () => {
  let date = new Date();
  const currentDate = moment(date).format("MM/DD/YYYY");
  return currentDate;
};

// *Date Format
export const getDate = (date) => {
  const newDate = moment(date).format("MM/DD/YYYY");
  return newDate;
};

// *Days Difference
export const getDateDifference = (startDate, endDate) => {
  const newStartDate = moment(startDate).format("YYYY-MM-DD");
  const newEndDate = moment(endDate).format("YYYY-MM-DD");
  let a = moment(newEndDate);
  let b = moment(newStartDate);
  let diff = a.diff(b, "days");
  return diff;
};

// *Email Regex
export const emailRegex =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

// *Cnic Regex
export const cnicRegex = /^[0-9+]{5}-[0-9+]{7}-[0-9]{1}$/;

// *Hex color Regex
export const hexColorRegex = /^#([0-9a-fA-F]{3}){1,2}$/;

export const checkCharacterValidation = (limit, characterLength, title) => {
  let res = characterLength > 0 && characterLength <= limit;
  if (!res) {
    alert(`${title} character length not valid.`);
  }
  return res;
};
export const checkImageValidation = (isImage, title) => {
  let res = isImage ? true : false;
  if (!res) {
    alert(`${title} image is required.`);
  }
  return res;
};
