const Images = {
  logo: require("../assets/images/PTN-logo.png"),
  userProfile: require("../assets/images/user-profile.svg").default,
  profilePlaceholder: require("../assets/images/profilePlaceHolder.jpg"),
  nicPlaceholder: require("../assets/images/nicPlaceholder.jpg"),
  noImage: require("../assets/images/noImage.png"),
  otpImage: require("../assets/images/otpImage.png"),
  iconPlaceholder: require("../assets/images/iconPlaceholder.png"),

  loginBanner: require("../assets/images/login-banner.png"),
  registerBanner: require("../assets/images/register-banner.png"),
  register: require("../assets/images/register.gif"),
  login: require("../assets/images/signIn.gif"),
  shipFreightIcon: require("../assets/images/cargoShip.png"),
  crossBorderFreightIcon: require("../assets/images/crossBorder.png"),
  airFreightIcon: require("../assets/images/plane.png"),
  railFreightIcon: require("../assets/images/train.png"),
  landFreightIcon: require("../assets/images/truckLog.png"),
};
export default Images;

export const Loading = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="100px"
      height="100px"
      viewBox="0 0 100 100"
      preserveAspectRatio="xMidYMid"
    >
      <path
        d="M10 50A40 40 0 0 0 90 50A40 42 0 0 1 10 50"
        fill="#0cba70"
        stroke="none"
      >
        <animateTransform
          attributeName="transform"
          type="rotate"
          dur="1s"
          repeatCount="indefinite"
          keyTimes="0;1"
          values="0 50 51;360 50 51"
        />
      </path>
    </svg>
  );
};
