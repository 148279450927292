import { AttachFileOutlined, ExpandMore } from "@mui/icons-material";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  FormControl,
  Typography,
} from "@mui/material";
import { Editor } from "@tinymce/tinymce-react";
import Images from "app/assets/Images";
import { PrimaryButton } from "app/components/Buttons/Button";
import colors from "app/styles/colors";
import React, { useRef, useState } from "react";

function CombineEditor({ title, handleData, data, characterLimit }) {
  const [imageFile, setImageFile] = useState(data?.image);
  const [contentLength, setContentLength] = useState(0);
  const editorRef = useRef(null);
  const uploadImage = async (e) => {
    try {
      if (e.target.files) {
        setImageFile(e.target.files[0]);
      }
    } catch (error) {
      console.log(
        "🚀 ~ file: AddEquipment.js ~ line 345 ~ uploadImage ~ error",
        error
      );
    }
  };

  const handleDataSubmission = () => {
    let contentLengthCheck = editorRef.current.getContent({
      format: "text",
    }).length;

    if (
      imageFile &&
      contentLengthCheck > 0 &&
      contentLengthCheck <= characterLimit
    ) {
      handleData({
        ...(imageFile?.name && { image: imageFile }),
        description: editorRef.current.getContent(),
      });
    } else if (imageFile == "") {
      alert("Please upload image to proceed.");
    } else {
      alert(`Character must be between 0 to ${characterLimit}.`);
    }
  };

  const handleEditorChange = (e, editor) => {
    const newContent = editor.getContent({ format: "text" });
    setContentLength(newContent?.length);
  };

  return (
    <Accordion
      sx={{
        mb: 1,
        boxShadow: "none",
        "&::before": {
          display: "none",
        },
      }}
    >
      <AccordionSummary expandIcon={<ExpandMore />}>
        <Typography variant="body1" fontWeight="bold" color="initial">
          {title}
        </Typography>
      </AccordionSummary>
      <AccordionDetails>
        <FormControl fullWidth sx={{ my: 0.5 }}>
          <Box
            sx={{
              border: `1px dashed ${colors.lightGray}`,
              textAlign: "center",
              height: 300,
              position: "relative",
              overflow: "hidden",
              p: 1,
            }}
          >
            <label htmlFor="file-input">
              <img
                src={
                  imageFile?.name
                    ? URL.createObjectURL(imageFile)
                    : imageFile
                    ? imageFile
                    : Images.iconPlaceholder
                }
                style={{
                  objectFit: "contain",
                  width: "100%",
                  height: 300,
                }}
              />
            </label>
            <input
              style={{ display: "none" }}
              id="file-input"
              type="file"
              onChange={(e) => uploadImage(e)}
              accept="image/*"
            />
          </Box>
        </FormControl>
        <Box sx={{ bgcolor: colors.white, borderRadius: "12px" }}>
          <Editor
            apiKey="x0zopq140mjc2x8ptqr6uybhoj5zq8owbik6g8ad28al4md8"
            onInit={(evt, editor) => (editorRef.current = editor)}
            initialValue={data?.description}
            init={{
              selector: "textarea",
              branding: false,
              height: 300,
              menubar: false,
              plugins: ["lists "],
              toolbar: "blocks | bold italic underline | numlist bullist ",
              content_style: "body { font-family:sans-serif; font-size:14px }",
            }}
            onEditorChange={handleEditorChange}
          />
          <Typography>
            {contentLength}/{characterLimit}
          </Typography>
        </Box>
        <PrimaryButton
          title="Save"
          onClick={() => handleDataSubmission()}
          style={{
            margin: "10px 0px",
          }}
        />
      </AccordionDetails>
    </Accordion>
  );
}

export default CombineEditor;
