import React, { useEffect, useState } from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Grid,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import Layout from "app/layout/Layout";
import colors from "app/styles/colors";
import BreadCrumbs from "app/components/Breadcrumbs/Breadcrumbs";
import TextEditor from "../../components/Editors/TextEditor";
import ImageEditor from "../../components/Editors/ImageEditor";
import { DeleteOutlineTwoTone, ExpandMore } from "@mui/icons-material";
import AddNewHistory from "app/components/Dialog/AddNewHistory";
import { SecondaryButton } from "app/components/Buttons/Button";
import GeneralServices from "app/apis/General/GeneralServices";
import { ErrorToaster, SuccessToaster } from "app/components/Toaster/Toaster";
import { LoadingCircle } from "app/components/UI/Loader";
import OurHistoryServices from "app/apis/OurHistory/OurHistoryServices";

function OurHistory() {
  const [showDialog, setShowDialog] = useState(false);
  const [mainBanner, setMainBanner] = useState("");
  const [firstImage, setFirstImage] = useState("");
  const [secondImage, setSecondImage] = useState("");
  const [ourVision, setOurVision] = useState({});
  const [historyItemList, setHistoryItemList] = useState([]);
  const [loader, setLoader] = useState(true);

  const submitBanner = async (route, data, oldData) => {
    try {
      let formData = new FormData();
      {
        oldData?.id && formData.append("id", oldData?.id);
      }
      formData.append("image", data, data?.name);
      let result = "";
      if (oldData) {
        result = await GeneralServices.updateBanner(route, formData);
      } else {
        result = await GeneralServices.createBanner(route, formData);
      }
      SuccessToaster(result?.message);
      setLoader(true);
    } catch (error) {
      ErrorToaster(error);
      console.log("🚀 ~ submitBanner ~ error:", error);
    }
  };

  const handleOurVision = async (data) => {
    try {
      let formData = new FormData();
      {
        ourVision?.id && formData.append("id", ourVision?.id);
      }
      formData.append("description", data.description);
      let result = "";
      if (ourVision?.id) {
        result = await OurHistoryServices.updateOurVision(formData);
      } else {
        result = await OurHistoryServices.createOurVision(formData);
      }
      SuccessToaster(result?.message);
      setLoader(true);
    } catch (error) {
      console.log("🚀 ~ handleOurVision ~ error:", error);
    }
  };

  const handleSubmitHistory = async (data) => {
    try {
      await OurHistoryServices.createHistory(data);
      SuccessToaster("History added successfully.");
      setLoader(true);
      setShowDialog(false);
    } catch (error) {
      console.log("🚀 ~ handleSubmitHistory ~ error:", error);
    }
  };

  const handleDeleteItem = async (id) => {
    let formData = new FormData();
    formData.append("id", id);
    try {
      await OurHistoryServices.deleteHistory(formData);
      SuccessToaster("History deleted successfully.");
      setLoader(true);
    } catch (error) {
      console.log("🚀 ~ handleDeleteItem ~ error:", error);
    }
  };

  // get apis ===========
  const getAllImages = async () => {
    try {
      let result = await GeneralServices.getBanner("HistoryBanner");
      let result1 = await GeneralServices.getBanner("HistoryFirstImg");
      let result2 = await GeneralServices.getBanner("HistorySecondImg");
      setMainBanner(result?.data[0]);
      setFirstImage(result1?.data[0]);
      setSecondImage(result2?.data[0]);
    } catch (error) {
      console.log("🚀 ~ getAllImages ~ error:", error);
    }
  };

  const getOurVision = async () => {
    try {
      let { data } = await OurHistoryServices.getOurVision();
      setOurVision(data[0]);
    } catch (error) {
      console.log("🚀 ~ getOurVision ~ error:", error);
    }
  };

  const getAllData = async () => {
    try {
      await getAllImages();
      await getOurVision();
      let result = await OurHistoryServices.getHistory();
      setHistoryItemList(result?.data);
    } catch (error) {
      console.log("🚀 ~ getAllData ~ error:", error);
    } finally {
      setLoader(false);
    }
  };

  useEffect(() => {
    if (loader) {
      getAllData();
    }
  }, [loader]);
  return (
    <Layout>
      <AddNewHistory
        handleClose={() => setShowDialog(false)}
        open={showDialog}
        characterLimit={150}
        handleSubmitFunc={() => {
          setLoader(true);
          setShowDialog(false);
        }}
      />
      <Box
        sx={{ backgroundColor: colors.lightGray, p: 3, borderRadius: "12px" }}
      >
        <Grid
          container
          alignItems="center"
          justifyContent="space-between"
          sx={{ bgcolor: colors.white, p: 2, borderRadius: "12px" }}
        >
          <Grid item xs={6}>
            <Typography variant="body1" fontWeight="bold" color="initial">
              Our History Management
            </Typography>
          </Grid>
          <Grid
            item
            xs={6}
            sx={{ display: "flex", justifyContent: "flex-end" }}
          >
            <BreadCrumbs currentPage="Our History Management" />
          </Grid>
        </Grid>
        {loader ? (
          <LoadingCircle />
        ) : (
          <Box sx={{ mt: 2 }}>
            <ImageEditor
              title={"Banner Image"}
              handleData={(e) => submitBanner("HistoryBanner", e, mainBanner)}
              uploadedImage={mainBanner}
            />
            <TextEditor
              title="Our Vision"
              characterLimit={570}
              data={ourVision}
              handleData={(e) => handleOurVision(e)}
            />
            <ImageEditor
              title={"First Image"}
              handleData={(e) => submitBanner("HistoryFirstImg", e, firstImage)}
              uploadedImage={firstImage}
            />
            <ImageEditor
              title={"Second Image"}
              handleData={(e) =>
                submitBanner("HistorySecondImg", e, secondImage)
              }
              uploadedImage={secondImage}
            />

            {/* ============Table============ */}
            <Accordion
              sx={{
                mb: 1,
                boxShadow: "none",
                "&::before": {
                  display: "none",
                },
              }}
            >
              <AccordionSummary expandIcon={<ExpandMore />}>
                <Typography variant="body1" fontWeight="bold" color="initial">
                  History Item List
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "flex-end",
                    p: 2,
                  }}
                >
                  <SecondaryButton
                    onClick={() => setShowDialog(!showDialog)}
                    title="Add History"
                  />
                </Box>
                <TableContainer component={Paper}>
                  <Table sx={{ minWidth: 650 }} aria-label="simple table">
                    <TableHead>
                      <TableRow sx={{ bgcolor: colors.primary }}>
                        <TableCell
                          sx={{ fontWeight: "bold", color: colors.white }}
                        >
                          Image
                        </TableCell>
                        <TableCell
                          sx={{ fontWeight: "bold", color: colors.white }}
                        >
                          Title
                        </TableCell>
                        <TableCell
                          sx={{ fontWeight: "bold", color: colors.white }}
                        >
                          Year
                        </TableCell>
                        <TableCell
                          sx={{ fontWeight: "bold", color: colors.white }}
                        >
                          Actions
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {historyItemList.map((row) => (
                        <TableRow
                          key={row.id}
                          sx={{
                            "&:last-child td, &:last-child th": { border: 0 },
                          }}
                        >
                          <TableCell component="th" scope="row">
                            <Typography
                              component={"img"}
                              sx={{
                                width: 70,
                                height: 70,
                                objectFit: "contain",
                                borderRadius: 70,
                              }}
                              src={row?.image}
                            />
                          </TableCell>
                          <TableCell component="th" scope="row">
                            {row.title}
                          </TableCell>
                          <TableCell>{row.year}</TableCell>
                          <TableCell>
                            <IconButton
                              onClick={() => handleDeleteItem(row?.id)}
                            >
                              <DeleteOutlineTwoTone color="error" />
                            </IconButton>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </AccordionDetails>
            </Accordion>
          </Box>
        )}
      </Box>
    </Layout>
  );
}

export default OurHistory;
