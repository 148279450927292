import React, { useEffect, useState } from "react";
import { Box, Grid, Typography } from "@mui/material";
import Layout from "app/layout/Layout";
import colors from "app/styles/colors";
import ImageEditorCombine from "app/components/Editors/ImageEditorCombine";
import TextEditorCombine from "app/components/Editors/TextEditorCombine";
import { PrimaryButton } from "app/components/Buttons/Button";
import { checkCharacterValidation, checkImageValidation } from "app/utils";
import { LoadingCircle } from "app/components/UI/Loader";
import { ErrorToaster } from "app/components/Toaster/Toaster";
import FreightsServices from "app/apis/Freights/FreightsServices";

let characterLength = {
  mainContent: 460,
  featureContent: 700,
};

function RailFreight() {
  const [allImages, setAllImages] = useState({});
  const [allContents, setAllContents] = useState({});
  const [itemData, setItemData] = useState({});
  const [loader, setLoader] = useState(true);

  const handleDataSubmission = async () => {
    try {
      if (
        checkCharacterValidation(
          characterLength.mainContent,
          allContents?.mainContent?.length,
          "Main Content"
        ) &&
        checkCharacterValidation(
          characterLength.featureContent,
          allContents?.featureContent?.length,
          "Feature Content"
        ) &&
        checkCharacterValidation(
          characterLength.featureContent,
          allContents?.specialFeatureContent?.length,
          "Special Feature Content"
        ) &&
        checkImageValidation(allImages?.bannerImage, "Main Banner") &&
        checkImageValidation(allImages?.firstImage, "First") &&
        checkImageValidation(allImages?.secondImage, "Second") &&
        checkImageValidation(allImages?.thirdImage, "Third") &&
        checkImageValidation(allImages?.fourthImage, "Fourth")
      ) {
        let formData = new FormData();
        {
          itemData?.id && formData.append("id", itemData?.id);
        }

        formData.append("description", allContents?.mainContent?.text);
        formData.append("special_service", allContents?.featureContent?.text);
        formData.append("features", allContents?.specialFeatureContent?.text);

        if (typeof allImages?.bannerImage == "object") {
          formData.append(
            "banner_image",
            allImages?.bannerImage,
            allImages?.bannerImage?.name
          );
        }
        if (typeof allImages?.firstImage == "object") {
          formData.append(
            "first_image",
            allImages?.firstImage,
            allImages?.firstImage?.name
          );
        }
        if (typeof allImages?.secondImage == "object") {
          formData.append(
            "second_image",
            allImages?.secondImage,
            allImages?.secondImage?.name
          );
        }
        if (typeof allImages?.thirdImage == "object") {
          formData.append(
            "third_image",
            allImages?.thirdImage,
            allImages?.thirdImage?.name
          );
        }
        if (typeof allImages?.fourthImage == "object") {
          formData.append(
            "fourth_image",
            allImages?.fourthImage,
            allImages?.fourthImage?.name
          );
        }

        if (itemData?.id) {
          await FreightsServices.update("RailFreight", formData);
        } else {
          await FreightsServices.create("RailFreight", formData);
        }
        setLoader(true);
      }
    } catch (error) {
      ErrorToaster(error);
      console.log("🚀 ~ handleDataSubmission ~ error:", error);
    }
  };

  const getItemData = async () => {
    let res = await FreightsServices.get("RailFreight");
    let data = res?.data[0];
    setItemData(data);
    setAllContents({
      mainContent: {
        text: data?.description,
        length: data?.description?.length,
      },
      specialFeatureContent: {
        text: data?.special_service,
        length: data?.special_service?.length,
      },
      featureContent: {
        text: data?.features,
        length: data?.features?.length,
      },
    });
    setAllImages({
      bannerImage: data?.banner_image,
      firstImage: data?.first_image,
      secondImage: data?.second_image,
      thirdImage: data?.third_image,
      fourthImage: data?.fourth_image,
    });
    setLoader(false);
  };

  useEffect(() => {
    if (loader) {
      getItemData();
    }
  }, [loader]);
  return (
    <Layout>
      <Box
        sx={{ backgroundColor: colors.lightGray, p: 3, borderRadius: "12px" }}
      >
        <Grid
          container
          alignItems="center"
          justifyContent="space-between"
          sx={{ bgcolor: colors.white, p: 2, borderRadius: "12px" }}
        >
          <Grid item xs={6}>
            <Typography variant="body1" fontWeight="bold" color="initial">
              Rail Freight Management
            </Typography>
          </Grid>
        </Grid>

        {loader ? (
          <LoadingCircle />
        ) : (
          <Box sx={{ mt: 2 }}>
            <ImageEditorCombine
              title={"Banner Image"}
              handleData={(e) => setAllImages({ ...allImages, bannerImage: e })}
              uploadedImage={allImages?.bannerImage}
            />
            <TextEditorCombine
              title="Main Content"
              characterLimit={characterLength.mainContent}
              data={itemData?.description}
              handleData={(e, contentLength) =>
                setAllContents({
                  ...allContents,
                  mainContent: {
                    text: e,
                    length: contentLength,
                  },
                })
              }
            />
            <ImageEditorCombine
              title={"First Image"}
              handleData={(e) => setAllImages({ ...allImages, firstImage: e })}
              uploadedImage={allImages?.firstImage}
            />
            <ImageEditorCombine
              title={"Second Image"}
              handleData={(e) => setAllImages({ ...allImages, secondImage: e })}
              uploadedImage={allImages?.secondImage}
            />
            <ImageEditorCombine
              title={"Third Image"}
              handleData={(e) => setAllImages({ ...allImages, thirdImage: e })}
              uploadedImage={allImages?.thirdImage}
            />
            <ImageEditorCombine
              title={"Fourth Image"}
              handleData={(e) => setAllImages({ ...allImages, fourthImage: e })}
              uploadedImage={allImages?.fourthImage}
            />
            <TextEditorCombine
              title="Special Services"
              characterLimit={characterLength.featureContent}
              data={itemData?.special_service}
              handleData={(e, contentLength) =>
                setAllContents({
                  ...allContents,
                  specialFeatureContent: {
                    text: e,
                    length: contentLength,
                  },
                })
              }
            />
            <TextEditorCombine
              title="Features"
              characterLimit={characterLength.featureContent}
              data={itemData?.features}
              handleData={(e, contentLength) =>
                setAllContents({
                  ...allContents,
                  featureContent: {
                    text: e,
                    length: contentLength,
                  },
                })
              }
            />

            <PrimaryButton
              title="Save"
              onClick={() => handleDataSubmission()}
              style={{
                margin: "10px 0px",
              }}
            />
          </Box>
        )}
      </Box>
    </Layout>
  );
}

export default RailFreight;
