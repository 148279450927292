import { Box, Typography } from "@mui/material";
import { Editor } from "@tinymce/tinymce-react";
import colors from "app/styles/colors";
import React, { memo, useRef, useState } from "react";

function TextEditorCombine({ title, characterLimit, handleData, data }) {
  const [contentLength, setContentLength] = useState(0);
  const editorRef = useRef(null);

  const handleEditorChange = (e, editor) => {
    const newContent = editor.getContent({ format: "text" });
    if (editor.getContent().length == 0) {
      setContentLength(0);
    } else {
      setContentLength(newContent?.length);
    }
    let dataLength = editor.getContent().length == 0 ? 0 : newContent?.length;
    handleData(e, dataLength);
  };
  return (
    <Box
      sx={{
        bgcolor: colors.white,
        p: 3,
        boxShadow: "none",
        "&::before": {
          display: "none",
        },
      }}
    >
      <Typography variant="body1" fontWeight="bold" color="initial">
        {title}
      </Typography>
      <Box sx={{ bgcolor: colors.white, borderRadius: "12px" }}>
        <Editor
          apiKey="x0zopq140mjc2x8ptqr6uybhoj5zq8owbik6g8ad28al4md8"
          onInit={(evt, editor) => (editorRef.current = editor)}
          initialValue={data ?? ""}
          init={{
            selector: "textarea",
            branding: false,
            height: 300,
            menubar: false,
            plugins: ["lists "],
            toolbar: "blocks | bold italic underline | numlist bullist ",
            content_style: "body { font-family:sans-serif; font-size:14px }",
          }}
          onEditorChange={handleEditorChange}
        />
        <Typography>
          {contentLength}/{characterLimit}
        </Typography>
      </Box>
    </Box>
  );
}

export default memo(TextEditorCombine);
