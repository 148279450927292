import { get, post, patch, deleted } from "app/apis";
import LeaderShipRoutes from "./LeaderShipRoutes";

const LeaderShipServices = {
  getLeader: async () => {
    const data = await get(LeaderShipRoutes.getLeader);
    return data;
  },
  createLeader: async (obj) => {
    const data = await post(LeaderShipRoutes.createLeader, obj);
    return data;
  },
  deleteLeader: async (obj) => {
    const data = await post(LeaderShipRoutes.deleteLeader, obj);
    return data;
  },
};

export default LeaderShipServices;
