import React, { useEffect } from 'react';
import { BrowserRouter, Navigate, Outlet, Route, Routes, } from 'react-router-dom';
import { createTheme, ThemeProvider } from '@mui/material';

import { ToasterContainer } from './components/Toaster/Toaster';
import useAuth from './hooks/useAuth';
import Login from './views/Auth/Login';
import AppRoutes from './routes/routes';
import AdminRoutes from './routes/admin.routes';
import CompanyRoutes from './routes/company.routes';
import colors from './styles/colors';

const theme = createTheme({
  palette: {
    primary: {
      main: colors.primary,
      contrastText: '#fff',
    },
    secondary: {
      main: colors.secondary,
      contrastText: '#fff',
    },
  },
  typography: {
    fontFamily: [
      'Roboto',
      'sans-serif',
    ].join(','),

  },
});

function App() {

  const { user, verify, role } = useAuth();

  useEffect(() => {
    verify();
  })

  return (
    <BrowserRouter>

      <ToasterContainer />

      <ThemeProvider theme={theme}>

        <Routes>

          <Route element={!user ? <Outlet /> : <Navigate to="/" />} >
            <Route path="/login" element={<Login />} />
            {/* <Route path="/register" element={<Register />} /> */}
          </Route>

          {/* ========== App Routes ========== */}
          <Route element={user ? <Outlet /> : <Navigate to="/login" />} >
            {AppRoutes.map((route, index) => (
              <Route
                key={index}
                path={route.path}
                element={route.component}
              />
            ))}
          </Route>

          {/* ========== Admin Routes ========== */}
          <Route element={user && role === "admin" ? <Outlet /> : <Navigate to="/login" />} >
            {AdminRoutes.map((route, index) => (
              <Route
                key={index}
                path={route.path}
                element={route.component}
              />
            ))}
          </Route>

          {/* ========== Company Routes ========== */}
          <Route element={user && role === "company" ? <Outlet /> : <Navigate to="/login" />} >
            {CompanyRoutes.map((route, index) => (
              <Route
                key={index}
                path={route.path}
                element={route.component}
              />
            ))}
          </Route>

        </Routes>

      </ThemeProvider>

    </BrowserRouter>
  );
}

export default App;
