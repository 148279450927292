import React, { useEffect, useState } from "react";
import { Box, Grid, Typography } from "@mui/material";
import Layout from "app/layout/Layout";
import colors from "app/styles/colors";
import TextEditor from "../../components/Editors/TextEditor";
import insightsServices from "app/apis/Insights/InsightsServices";
import { useNavigate, useParams } from "react-router-dom";
import { ErrorToaster } from "app/components/Toaster/Toaster";
import { checkCharacterValidation } from "app/utils";

function InsightsCard() {
  const params = useParams();
  const navigate = useNavigate();

  const [itemData, setItemData] = useState("");

  const handleDataSubmission = async (data) => {
    try {
      let formData = new FormData();
      {
        itemData?.id && formData.append("id", itemData?.id);
      }
      formData.append("item_id", params?.id);
      formData.append("description", data.description);
      let result = "";
      if (itemData?.id) {
        result = await insightsServices.updateInsightItemInfo(formData);
      } else {
        result = await insightsServices.createInsightItemInfo(formData);
      }
      navigate(-1);
    } catch (error) {
      ErrorToaster(error);
      console.log(
        "🚀 ~ file: Home.js:59 ~ handleIndustryWeMove ~ error:",
        error
      );
    }
  };

  const getItemData = async () => {
    try {
      let res = await insightsServices.getInsightItemInfo(params?.id);
      setItemData(res?.data[0]);
    } catch (error) {
      console.log("🚀 ~ getItemData ~ error:", error);
    }
  };

  useEffect(() => {
    getItemData();
  }, []);

  return (
    <Layout>
      <Box
        sx={{ backgroundColor: colors.lightGray, p: 3, borderRadius: "12px" }}
      >
        <Grid
          container
          alignItems="center"
          justifyContent="space-between"
          sx={{ bgcolor: colors.white, p: 2, borderRadius: "12px" }}
        >
          <Grid item xs={6}>
            <Typography variant="body1" fontWeight="bold" color="initial">
              Insights Item
            </Typography>
          </Grid>
        </Grid>
        <Box sx={{ mt: 2 }}>
          <TextEditor
            data={itemData}
            handleData={(e) => handleDataSubmission(e)}
            title="Main Content"
            characterLimit={4000}
          />
        </Box>
      </Box>
    </Layout>
  );
}

export default InsightsCard;
