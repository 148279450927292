import React, { useState, useEffect } from "react";
import { Box, Grid, Typography } from "@mui/material";
import Layout from "app/layout/Layout";
import colors from "app/styles/colors";
import BreadCrumbs from "app/components/Breadcrumbs/Breadcrumbs";
import TextEditor from "../../components/Editors/TextEditor";
import ImageEditor from "../../components/Editors/ImageEditor";
import HomeService from "app/apis/Home/HomeServices";
import WhoWeAreSection from "./shared/WhoWeAreSection";
import { LoadingCircle } from "app/components/UI/Loader";
import { ErrorToaster, SuccessToaster } from "app/components/Toaster/Toaster";
import CombineEditor from "app/components/Editors/CombineEditor";

function Home() {
  const [homeBanner, setHomeBanner] = useState("");
  const [whoWeAreData, setWhoWeAreData] = useState({});
  const [industryWeMoveData, setIndustryWeMoveData] = useState({});
  const [loader, setLoader] = useState(true);

  const submitHomeBanner = async (data) => {
    try {
      let formData = new FormData();
      {
        homeBanner?.id && formData.append("id", homeBanner?.id);
      }
      formData.append("image", data, data?.name);
      let result = "";
      if (homeBanner) {
        result = await HomeService.updateHomeBanner(formData);
      } else {
        result = await HomeService.createHomeBanner(formData);
      }
      SuccessToaster(result?.message);
      setLoader(true);
    } catch (error) {
      ErrorToaster(error);
      console.log("🚀 ~ file: Home.js:15 ~ submitHomeBanner ~ error:", error);
    }
  };

  const handleWhoWeAreSection = async (data) => {
    try {
      let formData = new FormData();
      {
        whoWeAreData?.id && formData.append("id", whoWeAreData?.id);
      }
      {
        data?.image?.name &&
          formData.append("image", data?.image, data?.image?.name);
      }
      formData.append("description", data?.description);
      let result = "";
      if (whoWeAreData?.id) {
        result = await HomeService.updateWhoWeAre(formData);
      } else {
        result = await HomeService.createWhoWeAre(formData);
      }
      SuccessToaster(result?.message);
      setLoader(true);
    } catch (error) {
      ErrorToaster(error);
      console.log("🚀 ~ file: Home.js:15 ~ submitHomeBanner ~ error:", error);
    }
  };

  const handleIndustryWeMove = async (data) => {
    try {
      let formData = new FormData();
      {
        industryWeMoveData?.id && formData.append("id", industryWeMoveData?.id);
      }
      formData.append("description", data.description);
      let result = "";
      if (industryWeMoveData?.id) {
        result = await HomeService.updateIndustryWeMove(formData);
      } else {
        result = await HomeService.createIndustryWeMove(formData);
      }
      SuccessToaster(result?.message);
      setLoader(true);
    } catch (error) {
      ErrorToaster(error);
      console.log(
        "🚀 ~ file: Home.js:59 ~ handleIndustryWeMove ~ error:",
        error
      );
    }
  };

  // Get api ==================

  const getHomeBanner = async () => {
    try {
      let { data } = await HomeService.getHomeBanner();
      setHomeBanner(data[0]);
    } catch (error) {
      console.log("🚀 ~ file: Home.js:29 ~ getHomeBanner ~ error:", error);
    }
  };

  const getWhoWeAre = async () => {
    try {
      let { data } = await HomeService.getWhoWeAre();
      setWhoWeAreData(data[0]);
    } catch (error) {
      console.log("🚀 ~ file: Home.js:61 ~ getWhoWeAre ~ error:", error);
    }
  };

  const getIndustryWeMove = async () => {
    try {
      let { data } = await HomeService.getIndustryWeMove();
      setIndustryWeMoveData(data[0]);
    } catch (error) {
      console.log("🚀 ~ file: Home.js:70 ~ getIndustryWeMove ~ error:", error);
    }
  };

  const getAllData = async () => {
    try {
      await getHomeBanner();
      await getWhoWeAre();
      await getIndustryWeMove();
    } catch (error) {
      console.log("🚀 ~ getAllData ~ error:", error);
    } finally {
      setLoader(false);
    }
  };

  useEffect(() => {
    if (loader) {
      getAllData();
    }
  }, [loader]);

  return (
    <Layout>
      <Box
        sx={{ backgroundColor: colors.lightGray, p: 3, borderRadius: "12px" }}
      >
        <Grid
          container
          alignItems="center"
          justifyContent="space-between"
          sx={{ bgcolor: colors.white, p: 2, borderRadius: "12px" }}
        >
          <Grid item xs={6}>
            <Typography variant="body1" fontWeight="bold" color="initial">
              Home Page Management
            </Typography>
          </Grid>
          <Grid
            item
            xs={6}
            sx={{ display: "flex", justifyContent: "flex-end" }}
          >
            <BreadCrumbs currentPage="Home Page Management" />
          </Grid>
        </Grid>
        {loader ? (
          <LoadingCircle />
        ) : (
          <Box sx={{ mt: 2 }}>
            <ImageEditor
              title={"Home Banner"}
              handleData={(e) => submitHomeBanner(e)}
              uploadedImage={homeBanner}
            />
            <CombineEditor
              title={"Who We Are Section"}
              data={whoWeAreData}
              handleData={(e) => handleWhoWeAreSection(e)}
              characterLimit={1110}
            />
            <TextEditor
              data={industryWeMoveData}
              handleData={(e) => handleIndustryWeMove(e)}
              title="Industry We Move"
              characterLimit={590}
            />
          </Box>
        )}
      </Box>
    </Layout>
  );
}

export default Home;
