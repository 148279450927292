import { ExpandMore } from "@mui/icons-material";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Box,
  Typography,
} from "@mui/material";
import { Editor } from "@tinymce/tinymce-react";
import { PrimaryButton } from "app/components/Buttons/Button";
import colors from "app/styles/colors";
import React, { useRef, useState } from "react";

function TextEditor({ title, characterLimit, handleData, data }) {
  const [contentLength, setContentLength] = useState(0);
  const editorRef = useRef(null);
  const handleEditorData = () => {
    if (editorRef.current) {
      let contentLengthCheck = editorRef.current.getContent({
        format: "text",
      }).length;
      if (contentLengthCheck > 0 && contentLengthCheck <= characterLimit) {
        handleData({ description: editorRef.current.getContent() });
      } else {
        alert(`Character must be between 0 to ${characterLimit}.`);
      }
    }
  };

  const handleEditorChange = (e, editor) => {
    const newContent = editor.getContent({ format: "text" });
    setContentLength(newContent?.length);
  };
  return (
    <Accordion
      sx={{
        mb: 1,
        boxShadow: "none",
        "&::before": {
          display: "none",
        },
      }}
    >
      <AccordionSummary expandIcon={<ExpandMore />}>
        <Typography variant="body1" fontWeight="bold" color="initial">
          {title}
        </Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Box sx={{ bgcolor: colors.white, borderRadius: "12px" }}>
          <Editor
            apiKey="x0zopq140mjc2x8ptqr6uybhoj5zq8owbik6g8ad28al4md8"
            onInit={(evt, editor) => (editorRef.current = editor)}
            initialValue={data?.description}
            init={{
              selector: "textarea",
              branding: false,
              height: 300,
              menubar: false,
              plugins: ["lists "],
              toolbar: "blocks | bold italic underline | numlist bullist ",
              content_style: "body { font-family:sans-serif; font-size:14px }",
            }}
            onEditorChange={handleEditorChange}
          />
          <Typography>
            {contentLength}/{characterLimit}
          </Typography>
          <PrimaryButton
            title="Save"
            onClick={handleEditorData}
            style={{
              margin: "10px 0px",
            }}
          />
        </Box>
      </AccordionDetails>
    </Accordion>
  );
}

export default TextEditor;
