import { Box, FormControl, Typography } from "@mui/material";
import Images from "app/assets/Images";
import colors from "app/styles/colors";
import React from "react";

function ImageEditorCombine({ title, handleData, uploadedImage }) {
  const uploadImage = async (e) => {
    try {
      if (e.target.files) {
        handleData(e.target.files[0]);
      }
    } catch (error) {
      console.log("🚀 ~ uploadImage ~ error:", error);
    }
  };

  return (
    <Box
      sx={{
        bgcolor: colors.white,
        p: 3,
        boxShadow: "none",
        "&::before": {
          display: "none",
        },
      }}
    >
      <Typography variant="body1" fontWeight="bold" color="initial">
        {title}
      </Typography>
      <FormControl fullWidth sx={{ my: 0.5 }}>
        <Box
          sx={{
            border: `1px dashed ${colors.lightGray}`,
            textAlign: "center",
            height: 300,
            position: "relative",
            overflow: "hidden",
            p: 1,
          }}
        >
          <label htmlFor={title}>
            <img
              src={
                typeof uploadedImage == "string"
                  ? uploadedImage
                  : uploadedImage
                  ? URL.createObjectURL(uploadedImage)
                  : Images.iconPlaceholder
              }
              style={{
                objectFit: "contain",
                width: "100%",
                height: 300,
              }}
            />
          </label>
          <input
            style={{ display: "none" }}
            id={title}
            type="file"
            onChange={(e) => uploadImage(e)}
            accept="image/*"
          />
        </Box>
      </FormControl>
    </Box>
  );
}

export default ImageEditorCombine;
