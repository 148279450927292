import { get, post } from "app/apis";
import insightsRoutes from "./InsightsRoutes";

const insightsServices = {
  createInsightItem: async (obj) => {
    const data = await post(insightsRoutes.createInsightItem, obj);
    return data;
  },
  createInsightItemInfo: async (obj) => {
    const data = await post(insightsRoutes.createInsightItemInfo, obj);
    return data;
  },
  updateInsightItemInfo: async (obj) => {
    const data = await post(insightsRoutes.updateInsightItemInfo, obj);
    return data;
  },
  getInsightItem: async () => {
    const data = await get(insightsRoutes.getInsightItem);
    return data;
  },
  getInsightItemInfo: async (id) => {
    const data = await get(insightsRoutes.getInsightItemInfo + `/${id}`);
    return data;
  },
  updateInsightItem: async (obj) => {
    const data = await post(insightsRoutes.updateInsightItem, obj);
    return data;
  },
  deleteInsightItem: async (obj) => {
    const data = await post(insightsRoutes.deleteInsightItem, obj);
    return data;
  },
};

export default insightsServices;
