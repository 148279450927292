import React, { useEffect, useState } from "react";
import {
  Box,
  Grid,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import Layout from "app/layout/Layout";
import colors from "app/styles/colors";
import BreadCrumbs from "app/components/Breadcrumbs/Breadcrumbs";
import { DeleteOutlineTwoTone } from "@mui/icons-material";
import { SecondaryButton } from "app/components/Buttons/Button";
import AddNewLeader from "app/components/Dialog/AddNewLeader";
import ImageEditor from "app/components/Editors/ImageEditor";
import { ErrorToaster, SuccessToaster } from "app/components/Toaster/Toaster";
import GeneralServices from "app/apis/General/GeneralServices";
import { LoadingCircle } from "app/components/UI/Loader";
import LeaderShipServices from "app/apis/LeaderShip/LeaderShipServices";

function OurLeadership() {
  const [showDialog, setShowDialog] = useState(false);
  const [mainBanner, setMainBanner] = useState("");
  const [loader, setLoader] = useState(true);
  const [employeeList, setEmployeeList] = useState([]);

  const submitBanner = async (route, data, oldData) => {
    try {
      let formData = new FormData();
      {
        oldData?.id && formData.append("id", oldData?.id);
      }
      formData.append("image", data, data?.name);
      let result = "";
      if (oldData) {
        result = await GeneralServices.updateBanner(route, formData);
      } else {
        result = await GeneralServices.createBanner(route, formData);
      }
      SuccessToaster(result?.message);
      setLoader(true);
    } catch (error) {
      ErrorToaster(error);
      console.log("🚀 ~ submitBanner ~ error:", error);
    }
  };

  const getAllData = async () => {
    try {
      let result1 = await GeneralServices.getBanner("LeadershipBanner");
      let result2 = await LeaderShipServices.getLeader();
      setMainBanner(result1?.data[0]);
      setEmployeeList(result2?.data);
    } catch (error) {
      console.log("🚀 ~ getAllData ~ error:", error);
    } finally {
      setLoader(false);
    }
  };

  const handleDeleteEmp = async (id) => {
    let formData = new FormData();
    formData.append("id", id);
    try {
      await LeaderShipServices.deleteLeader(formData);
      SuccessToaster("Deleted successfully.");
      setLoader(true);
    } catch (error) {
      console.log("🚀 ~ handleDeleteEmp ~ error:", error);
    }
  };

  useEffect(() => {
    if (loader) {
      getAllData();
    }
  }, [loader]);

  return (
    <Layout>
      <AddNewLeader
        open={showDialog}
        handleClose={() => setShowDialog(false)}
        handleSubmitFunc={() => {
          setLoader(true);
          setShowDialog(false);
        }}
      />
      <Box
        sx={{ backgroundColor: colors.lightGray, p: 3, borderRadius: "12px" }}
      >
        <Grid
          container
          alignItems="center"
          justifyContent="space-between"
          sx={{ bgcolor: colors.white, p: 2, borderRadius: "12px" }}
        >
          <Grid item xs={6}>
            <Typography variant="body1" fontWeight="bold" color="initial">
              Our Leadership Management
            </Typography>
          </Grid>
          <Grid
            item
            xs={6}
            sx={{ display: "flex", justifyContent: "flex-end" }}
          >
            <BreadCrumbs currentPage="Our Leadership Management" />
          </Grid>
        </Grid>
        {loader ? (
          <LoadingCircle />
        ) : (
          <Box sx={{ mt: 2 }}>
            <ImageEditor
              title={"Banner Image"}
              handleData={(e) =>
                submitBanner("LeadershipBanner", e, mainBanner)
              }
              uploadedImage={mainBanner}
            />
            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-end",
                bgcolor: colors.white,
                p: 2,
                borderRadius: "12px",
                my: 2,
              }}
            >
              <SecondaryButton
                onClick={() => setShowDialog(!showDialog)}
                title="Add Leader"
              />
            </Box>
            <TableContainer component={Paper}>
              <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                  <TableRow sx={{ bgcolor: colors.primary }}>
                    <TableCell sx={{ fontWeight: "bold", color: colors.white }}>
                      Image
                    </TableCell>
                    <TableCell sx={{ fontWeight: "bold", color: colors.white }}>
                      Name
                    </TableCell>
                    <TableCell sx={{ fontWeight: "bold", color: colors.white }}>
                      Designation
                    </TableCell>
                    <TableCell sx={{ fontWeight: "bold", color: colors.white }}>
                      Actions
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {employeeList.map((row) => (
                    <TableRow
                      key={row.name}
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    >
                      <TableCell component="th" scope="row">
                        <Typography
                          component={"img"}
                          sx={{
                            width: 70,
                            height: 70,
                            objectFit: "contain",
                            borderRadius: 70,
                          }}
                          src={row.image}
                        />
                      </TableCell>
                      <TableCell component="th" scope="row">
                        {row.name}
                      </TableCell>
                      <TableCell>{row.designation}</TableCell>
                      <TableCell>
                        <IconButton onClick={() => handleDeleteEmp(row?.id)}>
                          <DeleteOutlineTwoTone color="error" />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        )}
      </Box>
    </Layout>
  );
}

export default OurLeadership;
