const OurHistoryRoutes = {
  createHistoryVision: "/createHistoryVision",
  getHistoryVision: "/getHistoryVision",
  updateHistoryVision: "/updateHistoryVision",

  createHistory: "/createHistory",
  getHistory: "/getHistory",
  updateHistory: "/updateHistory",
  deleteHistory: "/deleteHistory",
};

export default OurHistoryRoutes;
