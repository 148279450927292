const IndustryWeMoveRoutes = {
  createIndustryMainText: "/createIndustryMainText",
  getIndustryMainText: "/getIndustryMainText",
  updateIndustryMainText: "/updateIndustryMainText",
  createIndustryItem: "/createIndustryItem",
  getIndustryItem: "/getIndustryItem",
  updateIndustryItem: "/updateIndustryItem",
  deleteIndustryItem: "/deleteIndustryItem",

  createIndustryItemInfo: "/createIndustryItemInfo",
  getIndustryItemInfo: "/getIndustryItemInfoById",
  updateIndustryItemInfo: "/updateIndustryItemInfo",
};

export default IndustryWeMoveRoutes;
