import React, { useEffect, useState } from "react";
import {
  Box,
  Grid,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import Layout from "app/layout/Layout";
import colors from "app/styles/colors";
import BreadCrumbs from "app/components/Breadcrumbs/Breadcrumbs";
import { LoadingCircle } from "app/components/UI/Loader";
import { SecondaryButton } from "app/components/Buttons/Button";
import { DeleteOutlineTwoTone } from "@mui/icons-material";
import AddJob from "app/components/Dialog/AddJob";
import CareerServices from "app/apis/Career/CareerServices";

function Careers() {
  const [loader, setLoader] = useState(true);
  const [showDialog, setShowDialog] = useState(false);
  const [jobList, setJobList] = useState([]);

  const getAllJobs = async () => {
    try {
      let result = await CareerServices.getCareerJob();
      setJobList(result?.data);
    } catch (error) {
      console.log("🚀 ~ getOurVision ~ error:", error);
    } finally {
      setLoader(false);
    }
  };

  const handleDeleteItem = async (id) => {
    try {
      await CareerServices.deleteCareerJob({ id });
      setLoader(true);
    } catch (error) {
      console.log("🚀 ~ getOurVision ~ error:", error);
    }
  };

  useEffect(() => {
    if (loader) {
      getAllJobs();
    }
  }, [loader]);

  return (
    <Layout>
      <AddJob
        handleClose={() => {
          setShowDialog(false);
        }}
        open={showDialog}
        reload={() => setLoader(true)}
        characterLimit={1500}
      />
      <Box
        sx={{ backgroundColor: colors.lightGray, p: 3, borderRadius: "12px" }}
      >
        <Grid
          container
          alignItems="center"
          justifyContent="space-between"
          sx={{ bgcolor: colors.white, p: 2, borderRadius: "12px" }}
        >
          <Grid item xs={6}>
            <Typography variant="body1" fontWeight="bold" color="initial">
              Careers
            </Typography>
          </Grid>
          <Grid
            item
            xs={6}
            sx={{ display: "flex", justifyContent: "flex-end" }}
          >
            <BreadCrumbs currentPage="Careers" />
          </Grid>
        </Grid>
        {loader ? (
          <LoadingCircle />
        ) : (
          <Box sx={{ mt: 2 }}>
            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-end",
                bgcolor: colors.white,
                p: 2,
                borderRadius: "12px",
                my: 2,
              }}
            >
              <SecondaryButton
                onClick={() => setShowDialog(true)}
                title="Create Job"
              />
            </Box>
            <TableContainer component={Paper}>
              <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                  <TableRow sx={{ bgcolor: colors.primary }}>
                    <TableCell sx={{ fontWeight: "bold", color: colors.white }}>
                      Title
                    </TableCell>
                    <TableCell sx={{ fontWeight: "bold", color: colors.white }}>
                      Subject
                    </TableCell>
                    <TableCell sx={{ fontWeight: "bold", color: colors.white }}>
                      Actions
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {jobList.map((row) => (
                    <TableRow
                      key={row.title}
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    >
                      <TableCell component="th" scope="row">
                        {row.title}
                      </TableCell>
                      <TableCell component="th" scope="row">
                        {row.subject}
                      </TableCell>
                      <TableCell>
                        <IconButton onClick={() => handleDeleteItem(row?.id)}>
                          <DeleteOutlineTwoTone color="error" />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        )}
      </Box>
    </Layout>
  );
}

export default Careers;
