const colors = {
  primary: "#0cba70",
  primaryLight: "#d5f7e9",
  primaryDark: "#0c7548",
  secondary: "#2f2e41",
  secondaryLight: "#64959c",
  secondaryDark: "#2f2e41c7",
  artyClickAmber: "#ffc107",
  eggSour: "#eddaa95e",
  dairyCream: "#eddaa95e",
  darkMintGreen: "rgb(0, 200, 83)",
  grapeFruit: "rgb(216, 67, 21)",
  dawnPink: "#FBE9E7",
  tertiary: "#161624",
  textPrimary: "#262626",
  textSecondary: "#b0b0b0",
  bondiBlue: "#03989E",
  bondiBlueLight: "#17c7cf",
  textLight: "#484D50",
  candleLight: "#FFD824",
  white: "#ffffff",
  whiteSmoke: "#f6f6f6",
  mercury: "#f5f9fc",
  black: "#222222",
  lightGray: "#e3e8e885",
  danger: "#FF2121",
  ebonyClay: "#252340",
  gunMetal: "#263238",
  success: "#79AA5A",
  cloud: "#C4C4C4",
  themeBlue:"#251367"
};

export default colors;
