import React, { Fragment, useEffect, useState } from "react";
import { Box, Grid, Typography } from "@mui/material";
import Layout from "app/layout/Layout";
import colors from "app/styles/colors";
import ImageEditorCombine from "app/components/Editors/ImageEditorCombine";
import TextEditorCombine from "app/components/Editors/TextEditorCombine";
import { PrimaryButton } from "app/components/Buttons/Button";
import { checkCharacterValidation, checkImageValidation } from "app/utils";
import IndustryWeMoveServices from "app/apis/IndustryWeMove/IndustryWeMoveServices";
import { useNavigate, useParams } from "react-router-dom";
import { LoadingCircle } from "app/components/UI/Loader";
import { ErrorToaster } from "app/components/Toaster/Toaster";

let characterLength = {
  mainContent: 740,
  paragraphLength: 230,
};

function IndustryWeMoveCard() {
  const params = useParams();
  const navigate = useNavigate();
  const [loader, setLoader] = useState(true);
  const [allImages, setAllImages] = useState({});
  const [allContents, setAllContents] = useState({});
  const [itemData, setItemData] = useState({});

  const handleDataSubmission = async () => {
    try {
      if (
        checkCharacterValidation(
          characterLength.paragraphLength,
          allContents?.firstParagraph?.length,
          "First Paragraph"
        ) &&
        checkCharacterValidation(
          characterLength.paragraphLength,
          allContents?.secondParagraph?.length,
          "Second Paragraph"
        ) &&
        checkCharacterValidation(
          characterLength.mainContent,
          allContents?.mainContent?.length,
          "Main Content"
        ) &&
        checkImageValidation(allImages?.firstImage, "First") &&
        checkImageValidation(allImages?.secondImage, "Second")
      ) {
        let formData = new FormData();
        {
          itemData?.id && formData.append("id", itemData?.id);
        }
        formData.append("item_id", params?.id);
        formData.append("first_description", allContents?.firstParagraph?.text);
        formData.append(
          "second_description",
          allContents?.secondParagraph?.text
        );
        formData.append("main_description", allContents?.mainContent?.text);

        if (typeof allImages?.firstImage == "object") {
          formData.append(
            "first_image",
            allImages?.firstImage,
            allImages?.firstImage?.name
          );
        }
        if (typeof allImages?.secondImage == "object") {
          formData.append(
            "second_image",
            allImages?.secondImage,
            allImages?.secondImage?.name
          );
        }

        if (itemData?.id) {
          await IndustryWeMoveServices.updateIndustryItemInfo(formData);
        } else {
          await IndustryWeMoveServices.createIndustryItemInfo(formData);
        }
        navigate(-1);
      }
    } catch (error) {
      ErrorToaster(error);
      console.log("🚀 ~ handleDataSubmission ~ error:", error);
    }
  };

  const getItemData = async () => {
    let res = await IndustryWeMoveServices.getIndustryItemInfo(params?.id);
    let data = res?.data[0]; // this needs to change
    setItemData(data);
    setAllContents({
      firstParagraph: {
        text: data?.first_description,
        length: data?.first_description?.length,
      },
      secondParagraph: {
        text: data?.second_description,
        length: data?.second_description?.length,
      },
      mainContent: {
        text: data?.main_description,
        length: data?.main_description?.length,
      },
    });
    setAllImages({
      firstImage: data?.first_image,
      secondImage: data?.second_image,
    });
    setLoader(false);
  };

  useEffect(() => {
    getItemData();
  }, []);

  return (
    <Layout>
      <Box
        sx={{ backgroundColor: colors.lightGray, p: 3, borderRadius: "12px" }}
      >
        <Grid
          container
          alignItems="center"
          justifyContent="space-between"
          sx={{ bgcolor: colors.white, p: 2, borderRadius: "12px" }}
        >
          <Grid item xs={6}>
            <Typography variant="body1" fontWeight="bold" color="initial">
              Industry We Move Management Item
            </Typography>
          </Grid>
        </Grid>
        {loader ? (
          <LoadingCircle />
        ) : (
          <Fragment>
            <Box sx={{ mt: 2 }}>
              <ImageEditorCombine
                title={"First Image"}
                handleData={(e) =>
                  setAllImages({ ...allImages, firstImage: e })
                }
                uploadedImage={allImages?.firstImage}
              />
              <TextEditorCombine
                title="First Paragraph"
                characterLimit={characterLength.paragraphLength}
                data={itemData?.first_description}
                handleData={(e, contentLength) =>
                  setAllContents({
                    ...allContents,
                    firstParagraph: {
                      text: e,
                      length: contentLength,
                    },
                  })
                }
              />

              <ImageEditorCombine
                title={"Second Image"}
                handleData={(e) =>
                  setAllImages({ ...allImages, secondImage: e })
                }
                uploadedImage={allImages?.secondImage}
              />
              <TextEditorCombine
                title="Second Paragraph"
                characterLimit={characterLength.paragraphLength}
                data={itemData?.second_description}
                handleData={(e, contentLength) =>
                  setAllContents({
                    ...allContents,
                    secondParagraph: {
                      text: e,
                      length: contentLength,
                    },
                  })
                }
              />
              <TextEditorCombine
                title="Main Content"
                characterLimit={characterLength.mainContent}
                data={itemData?.main_description}
                handleData={(e, contentLength) =>
                  setAllContents({
                    ...allContents,
                    mainContent: {
                      text: e,
                      length: contentLength,
                    },
                  })
                }
              />

              <PrimaryButton
                title="Save"
                onClick={() => handleDataSubmission()}
                style={{
                  margin: "10px 0px",
                }}
              />
            </Box>
          </Fragment>
        )}
      </Box>
    </Layout>
  );
}

export default IndustryWeMoveCard;
